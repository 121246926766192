import axios from "axios";

/**
 * Fetch advertisement banners based on keyword and municipality.
 * @param {string} municipality - The municipality slug.
 * @param {string} keyword - The search keyword.
 * @returns {Promise<Object[]>} - A list of advertisement banners.
 */
export const fetchAdvertisements = async (municipality, keyword) => {
  try {
    const today = new Date().toISOString().split("T")[0];
    const currentTime = new Date().toISOString().split("T")[1].split(".")[0];

    const queryParams = {
      active: true,
      selected_municipalities__slug__icontains: municipality,
      contract_start__lte: today,
      contract_end__gte: today,
      start_time__lte: currentTime,
      end_time__gte: currentTime,
    };

    if (keyword) {
      queryParams.q__keywords__name__icontains = keyword;
      queryParams.q__keywords__name_nl__icontains = keyword;
      queryParams.q__keywords__name_fr__icontains = keyword;
      queryParams.q__keywords__name_de__icontains = keyword;
      queryParams.q__keywords__name_en__icontains = keyword;
      queryParams.q__categories__name__icontains = keyword;
      queryParams.q__categories__name_nl__icontains = keyword;
      queryParams.q__categories__name_fr__icontains = keyword;
      queryParams.q__categories__name_de__icontains = keyword;
      queryParams.q__categories__name_en__icontains = keyword;
    }

    queryParams.ordering = "-score";

    const url = `${process.env.REACT_APP_BASE_MEDIA_URL}/core/new-api/core/advertisementbanner/?populate=images&${Object.keys(queryParams)
      .map((key) => `${key}=${queryParams[key]}`)
      .join("&")}&distinct=true`;

    const response = await axios.get(url, {
      headers: {
        Authorization: "Token ae0e4f2df7b0d54ce458ca65ca53849e92691e86",
      },
    });

    return response.data.results || [];
  } catch (error) {
    console.error("Error fetching advertisement banners:", error);
    return [];
  }
};

export const fetchAdvertisementsForEstablishment = async (establishmentNumber) => {
  try {
    const today = new Date().toISOString().split("T")[0];
    const currentTime = new Date().toISOString().split("T")[1].split(".")[0];

    const queryParams = {
      active: true,
      advertisement_banner__contract_start__lte: today, 
      advertisement_banner__contract_end__gte: today,
      advertisement_banner__start_time__lte: currentTime,
      advertisement_banner__end_time__gte: currentTime,
    };

    const establishmentUrl = `${process.env.REACT_APP_BASE_MEDIA_URL}/core/new-api/core/establishmentadvertisementbannerlink/?populate=advertisement_banner.images&establishment__number=${establishmentNumber}&${Object.keys(queryParams)
      .map((key) => `${key}=${queryParams[key]}`)
      .join("&")}&distinct=true`;

    const establishmentResponse = await axios.get(establishmentUrl, {
      headers: {
        Authorization: "Token ae0e4f2df7b0d54ce458ca65ca53849e92691e86",
      },
    });

    const enterpriseUrl = `${process.env.REACT_APP_BASE_MEDIA_URL}/core/new-api/core/enterpriseadvertisementbannerlink/?populate=advertisement_banner.images&enterprise__establishments__number=${establishmentNumber}&${Object.keys(queryParams)
      .map((key) => `${key}=${queryParams[key]}`)
      .join("&")}&distinct=true`;

    const enterpriseResponse = await axios.get(enterpriseUrl, {
      headers: {
        Authorization: "Token ae0e4f2df7b0d54ce458ca65ca53849e92691e86",
      },
    });

    const groupsUrl = `${process.env.REACT_APP_BASE_MEDIA_URL}/core/new-api/core/groupadvertisementbannerlink/?populate=advertisement_banner.images&group__osngroupestablishment_group__establishment__number=${establishmentNumber}&${Object.keys(queryParams)
      .map((key) => `${key}=${queryParams[key]}`)
      .join("&")}&distinct=true`;

    const groupsResponse = await axios.get(groupsUrl, {
      headers: {
        Authorization: "Token ae0e4f2df7b0d54ce458ca65ca53849e92691e86",
      },
    });

    // get all the responses and combine them into one array and sort them in descending order by score
    const banners = [...establishmentResponse.data.results, ...enterpriseResponse.data.results, ...groupsResponse.data.results].sort((a, b) => b.score - a.score);
  
    return banners;
  } catch (error) {
    console.error("Error fetching advertisement banners:", error);
    return [];
  }
}