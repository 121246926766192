import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import Icon from "@identitybuilding/idb-react-iconlib";
import React, { useState } from "react";

const Navigation = (props) => {
  const [networks, setNetworks] = useState(false);

  const lang = useSelector((state) => state.general.lang);
  const nis = useSelector((state) => state.general.nis);
  const location = useSelector((state) => state.search.location);
  const translate = useSelector((state) => state.general.translate);

  const LogoSA = require("../../library/imgs/logos/logo_shoppaworld.svg");
  const LogoSA_Invert = require("../../library/imgs/logos/logo_shoppaworld-invert.svg");
  const LogoPN = require(`../../library/imgs/logos/pn/logo_pn_${lang}.svg`);
  const LogoPN_Invert = require(`../../library/imgs/logos/pn/logo_pn_invert_${lang}.svg`);
  const LogoCN = require(`../../library/imgs/logos/cn/logo_cn_${lang}.svg`);
  const LogoCN_Invert = require(`../../library/imgs/logos/cn/logo_cn_invert_${lang}.svg`);
  const LogoESN = require(`../../library/imgs/logos/esn/logo_esn_${lang}.svg`);
  const LogoESN_Invert = require(`../../library/imgs/logos/esn/logo_esn_invert_${lang}.svg`);
  const LogoPL = require(`../../library/imgs/logos/pl/logo_pl_${lang}.svg`);
  const LogoPL_Invert = require(`../../library/imgs/logos/pl/logo_pl_invert_${lang}.svg`);
  let networkFunction = "";

  // let value_1 = props.match && props.match.params.value_1 ? props.match.params.value_1.replace(/\+/g, " ") : "";
  let value_2 =
    props.match && props.match.params.value_2
      ? props.match.params.value_2.replace(/\+/g, " ")
      : "";
  let url = props.match.url;
  let path = "";

  if (lang === "fr") {
    if (url.includes(translate("search_slug"))) {
      path = `/chercher/${location.length > 0 &&
        location[0].slug &&
        location[0].slug.toLowerCase()
        }${value_2 ? `/${value_2}/page=1` : `/page=1`}`;
    }
    if (url.includes("business")) {
      path = props.match ? props.match.url : "";
      if (props.match) {
        let params = props.match.params;
        if (params.mod === "marques")
          path = `/business/${params.id}/${params.name}/merken`;
        if (params.mod === "offres-actuelles")
          path = `/business/${params.id}/${params.name}/huidige-aanbiedingen`;
        if (params.mod === "activités")
          path = `/business/${params.id}/${params.name}/activiteiten`;
      }
    }
  } else if (lang === "nl") {
    if (url.includes(translate("search_slug"))) {
      path = `/zoeken/${location.length > 0 &&
        location[0].slug &&
        location[0].slug.toLowerCase()
        }${value_2 ? `/${value_2}/pagina=1` : `/pagina=1`}`;
    }
    if (url.includes("business")) {
      path = props.match ? props.match.url : "";
      if (props.match) {
        let params = props.match.params;
        if (params.mod === "merken")
          path = `/business/${params.id}/${params.name}/marques`;
        if (params.mod === "huidige-aanbiedingen")
          path = `/business/${params.id}/${params.name}/offres-actuelles`;
        if (params.mod === "activiteiten")
          path = `/business/${params.id}/${params.name}/activités`;
      }
    }
  }
  const timeoutFunction = () => {
    networkFunction = setTimeout(() => {
      setNetworks(false);
    }, 750);
  };

  return (
    <nav
      className={networks ? "active" : ""}
      id={props.Zindex ? "active" : ""}
      onMouseLeave={() => networks && timeoutFunction()}
      onMouseOverCapture={() => networks && clearTimeout(networkFunction)}
      style={{
        backgroundColor: props.background ? "white" : "transparent",
        boxShadow: props.background ? "0 0 10px rgba(0,0,0,.1)" : "none",
      }}
    >
      {/* Networks navigation */}
      <div className="networks">
        {!nis ? (
          <a
            className="osn networkLogo"
            href={
              lang === "nl"
                ? "https://100procentlokaal.be"
                : "https://www.100pourcentlocale.be"
            }
            rel="noopener noreferrer"
            target="_blank"
          >
            <img
              className="lazyload logo"
              data-src={LogoPL_Invert}
              alt={translate("pl_alt")}
            />
            <img
              className="lazyload logo logoOriginal"
              data-src={LogoPL}
              alt={translate("pl_alt")}
            />
          </a>
        ) : (
          <a
            className="osn networkLogo"
            href={
              lang === "nl"
                ? "https://ondernemersnetwerk.be"
                : "https://www.reseauentrepreneurs.be"
            }
            rel="noopener noreferrer"
            target="_blank"
          >
            <img
              className="lazyload logo"
              data-src={LogoESN_Invert}
              alt={translate("esn_alt")}
            />
            <img
              className="lazyload logo logoOriginal"
              data-src={LogoESN}
              alt={translate("esn_alt")}
            />
          </a>
        )}
        <a
          className="cn networkLogo"
          href="https://catalogusnetwerk.be"
          rel="noopener noreferrer"
          target="_blank"
        >
          <img
            className="lazyload logo"
            data-src={LogoCN_Invert}
            alt={translate("cn_alt")}
          />
          <img
            className="lazyload logo logoOriginal"
            data-src={LogoCN}
            alt={translate("cn_alt")}
          />
        </a>
        <a
          className="pn networkLogo"
          href="https://promonetwerk.be"
          rel="noopener noreferrer"
          target="_blank"
        >
          <img
            className="lazyload logo"
            data-src={LogoPN_Invert}
            alt={translate("pn_alt")}
          />
          <img
            className="lazyload logo logoOriginal"
            data-src={LogoPN}
            alt={translate("pn_alt")}
          />
        </a>
        <a
          className="sa networkLogo"
          href="https://shoppa.world"
          rel="noopener noreferrer"
          target="_blank"
        >
          <img
            data-src={LogoSA_Invert}
            className="lazyload logo"
            alt="Logo ShoppA.world"
          />
          <img
            className="lazyload logo logoOriginal"
            data-src={LogoSA}
            alt="Logo ShoppA.world"
          />
        </a>
      </div>

      {/* Navigation menu */}
      <div className="nav_menu">
        {/* Navigation logo */}
     

        <a
          className="navLogo"
          href="/"
          onClick={(e) => {
            e.preventDefault(); // Prevent default anchor behavior
            window.location.href = "/"; // Navigate to the root
          }}
        >
          <img
            height="25px"
            width="25px"
            alt={translate("esn_alt")}
            className="logo logoOriginal"
            src={require(`../../library/imgs/logos/esn/logo_esn_small_${lang}.svg`)}
          />
          {/* Logo for mobile */}
          <img
            height="25px"
            width="25px"
            alt={translate("esn_alt")}
            className="logo logoOriginal"
            src={require(`../../library/imgs/logos/esn/logo_esn_${lang}.svg`)}
          />
        </a>


        {/* Navigation menu items */}
        <div className="nav_buttons">
          {/* Button to open the networks menu */}
          <a
            className="nav_button my_company"
            href={`https://${lang === "fr" ? "wijzig.100procentlokaal.be?lang=fr" : "wijzig.100procentlokaal.be?lang=nl"}`}
            rel="noreferrer noopener"
            target="_blank"
            onClick={(e) => {
              e.preventDefault();
              const url = `https://${lang === "fr" ? "wijzig.100procentlokaal.be?lang=fr" : "wijzig.100procentlokaal.be?lang=nl"}`
              // window.location.href = url;
              window.open(url)
            }}
          >
            {translate("my_company")}
          </a>
          <div
            className={`nav_button network ${networks ? "active" : ""}`}
            onClick={() => setNetworks(!networks)}
          >
            {translate("networks")}
          </div>

          {/* Language dropdownmenu */}
          <div className="nav_button">
            <Icon name="Website" />
            {lang === "nl"
              ? "Nederlands"
              : lang === "fr"
                ? "Français"
                : "English"}
            <div className="lang_options">
              {nis ? (
                lang === "nl" ? (
                  <a
                    href={`https://${nis ? `${nis}.` : ""
                      }100pourcentlocale.be${path}`}
                  >
                    Français
                  </a>
                ) : (
                  <a
                    href={`https://${nis ? `${nis}.` : ""
                      }100procentlokaal.be${path}`}
                  >
                    Nederlands
                  </a>
                )
              ) : lang === "nl" ? (
                <a href={`https://reseauentrepreneurs.be${path}`}>Français</a>
              ) : (
                <a href={`https://ondernemersnetwerk.be${path}`}>Nederlands</a>
              )}
              {/* {lang === "en" ? <a href="https://reseauentrepreneurs.be">Français</a>
								: <a href="https://entrepreneursnetwork.be">English</a>} */}
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navigation;
