import React, { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Icon from "@identitybuilding/idb-react-iconlib";

const AdvertisementCard = ({ banner, forBusinessPage = false }) => {
    const [loading, setLoading] = useState(true);

    const randomNumberBetween10And2000 = Math.floor(Math.random() * 2000) + 10;

    const sliderSettings = {
        arrows: false,
        autoplay: true,
        dots: false,
        infinite: true,
        speed: 4500 + randomNumberBetween10And2000,
        slidesToScroll: 1,
        pauseOnHover: true,
    };

    const openAdLink = async (adImage) => {
        window.open(adImage.link, "_blank");

        try {
            const today = new Date().toISOString().split("T")[0]; // Get today's date in YYYY-MM-DD format

            // Check if a click record exists for today's date
            const response = await fetch(
                `${process.env.REACT_APP_BASE_MEDIA_URL}/core/new-api/register/advertisement-banner-click/${banner.id}/?date=${today}`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            );

            // console.log("Advertisement Banner Click Response:", response);
        } catch (error) {
            console.error("Error registering advertisement click:", error);
        }
    };

    const handleImageLoad = () => {
        setLoading(false);
    };

    return (
        <div
            className="advertisementCardsContainer"
            style={forBusinessPage ? { justifyContent: "center", display: "flex" } : {}}
        >
            <div className="advertisementCard">
                <div className="advertisementCardImage">
                    {banner.images.length > 1 ? (
                        <Slider {...sliderSettings}>
                            {banner.images.map((image, idx) => (
                                <div key={idx} className="adImageWrapper" style={{ height: "200px" }}>
                                    {loading && (
                                        <div style={{ height: '200px', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                            <Icon name="Loading" animated />
                                        </div>
                                    )}
                                    <img
                                        src={image.image}
                                        loading="lazy"
                                        onClick={() => openAdLink(image, banner.id)}
                                        alt={`Ad ${banner.id} - Image ${idx + 1}`}
                                        className={`adImage ${loading ? "hidden" : ""}`}
                                        onLoad={handleImageLoad}
                                        key={image.image + idx}
                                    />
                                </div>
                            ))}
                        </Slider>
                    ) : (
                        <>
                            {loading && (
                                <div style={{ height: '200px', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <Icon name="Loading" animated />
                                </div>
                            )}
                            <img
                                src={banner.images[0]?.image}
                                onClick={() => openAdLink(banner.images[0])}
                                alt={`Ad ${banner.id}`}
                                key={banner.images[0].image}
                                loading="lazy"
                                className={`adImage ${loading ? "hidden" : ""}`}
                                onLoad={handleImageLoad}
                            />
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default AdvertisementCard;