import {
    toggleView,
    updateSearch,
    emptyLocationSelection,
    addToLocationSelection,
    updateAsCateogy,
    updateCategory,
    updateFilter,
  } from "../../actions";
  import { useDispatch, useSelector } from "react-redux";
  import {
    SearchEntity,
    searchLocation,
    AutoSuggestionKeyword,
  } from "../../services";
  // import BusinessCard from "./BusinessCard";
  // import FilterMenu from "./FilterMenu";
  import Footer from "../_general/Footer";
  import MapMarker from "@identitybuilding/idb-react-map/dist/MapMarker";
  import Meta from "../_general/Meta";
  import Navigation from "../_general/Navigation";
  import Pagination from "../_general/Pagination";
  import React, { useEffect, useState, lazy, Suspense } from "react";
  // import SearchBar from "../search/SearchBar";
  // import SearchFilter from '../search/SearchFilter'
  import Redirects from "../_general/Redirects";
  import { latLngBounds } from "leaflet";
  import { Link } from "react-router-dom";
  import Icon from "@identitybuilding/idb-react-iconlib";
  import { useLocation } from "react-router-dom";
  import Slider from "react-slick";
  import "slick-carousel/slick/slick.css";
  import "slick-carousel/slick/slick-theme.css";
  
  import { fetchAdvertisements } from "../../utils/advertisements";
  import axios from "axios";
  import AdvertisementCard from "../Advertisements/AdvertisementCard";
  import AlternateSearchBar from "../../components/search/AlternateSearchBar";
  
  const BusinessCard = lazy(() => import("./BusinessCard"));
  const SearchBar = lazy(() => import("../search/SearchBar"));
  const SearchFilter = lazy(() => import("../search/SearchFilter"));
  
  
  
  // const MapMarker = lazy(() => import('@identitybuilding/idb-react-map/dist/MapMarker'))
  
  var engine = require("store/src/store-engine");
  var storages = [require("store/storages/cookieStorage")];
  var plugins = [
    require("store/plugins/defaults"),
    require("store/plugins/expire"),
  ];
  var store = engine.createStore(storages, plugins);
  
  const AlternateResultsPage = (props) => {
    const [loading, setLoading] = useState({});
  
    // Used for the map component, to check if the map is zoomed in or not
    const [zoomed, setZoomed] = useState(false);
  
    // Used for making markers active or not
    let [activeId, setActiveId] = useState(false);
  
    // Making sure that when data is not fully loaded, the map isn't shown, this prevents bugs
    const [loadingMap, setLoadingMap] = useState(true);
  
    // This stores the entity_id of the previous clicked marker
    const [currentEntity, setCurrentEntity] = useState({ data: {}, id: "" });
    const [advertisementBanners, setAdvertisementBanners] = useState([]);
    const [loadingAds, setLoadingAds] = useState(true);
  
    // We get this data from the map package, it holds the props for the map component
    const [mapSettings, setMapSettings] = useState({});
  
    // we store all cards in one array, so that we can send this var to the map component
    let [allResults, setAllResults] = useState([]);
  
    const [originalLatLong, setOriginalLatLong] = useState({});
    const [originalZoom, setOriginalZoom] = useState({});
    const [bounds, setBounds] = useState(true);
    const [hasSearched, setHasSearched] = useState(false);
  
    const [_isMounted, setIsMounted] = useState(true);
    const [resultData, setResultData] = useState({
      entities: [],
      error: false,
      pagination: {},
      ids: [],
    });
  
    const [selectedAd, setSelectedAd] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
  
  
    const handleAdClick = (ad) => {
      setSelectedAd(ad);
      setIsModalOpen(true);
    };
    const dispatch = useDispatch();
  
    const { lang, translate } = useSelector((state) => state.general);
    const map = useSelector((state) => state.map);
    const view = useSelector((state) => state.filter.view);
    const path = useSelector((state) => state.general.search_path);
    const location = useSelector((state) => state.search.location);
    let categoryID = useSelector((state) => state.search.categoryID);
  
    // is100ProcentLokaal (if 100procentlokaal in the url)
    const is100ProcentLokaal = window.location.href.includes('100procentlokaal') || window.location.href.includes('100pourcentlocale');

  
    let value_1 =
      props.match && props.match.params.value_1
        ? props.match.params.value_1.replace(/\+/g, " ")
        : "";
    let value_2 =
      props.match && props.match.params.value_2
        ? props.match.params.value_2.replace(/\+/g, " ".replaceAll('%2F', '/'))
        : "";
    let page =
      props.match && props.match.params.page ? props.match.params.page : "";
    let firstId =
      props.match && props.match.params.id ? props.match.params.id : "";
  
    const useQuery = () => {
      return new URLSearchParams(useLocation().search);
    };
    // Use the array keys to switch between cardsView or mapView
    const switchView = (e) => {
      // Excecutes function if not focussed in search inputfields
      if (e.target.id !== "keyword_input" && e.target.id !== "location_input") {
        if (e.keyCode === 37 || e.keyCode === 39) {
          dispatch(toggleView(e.keyCode === 37 ? 0 : 1));
        }
      }
    };
  
    // This function is also accessable in the searchbar
    const switchViewListener = (e) => {
      if (e) document.addEventListener("keydown", (e) => switchView(e));
      else document.removeEventListener("keydown", () => switchView());
      allResults = [];
    };
  
    // look if category is true in the query params
    let as_cate = useQuery().get("category");
  
    // Based on the type of query, we're going to get our data now
    const getData = async (live, filterArray, data) => {
      emptyResults();
      let pages = { ...resultData.pagination };
      pages.current_page = page;
  
      setLoading(true);
      setResultData({ ...resultData, error: false });
  
      let result = "";
      const CancelToken = axios.CancelToken;
      const source = CancelToken.source();
      // setTimeout(async () => {
      // 	source.cancel()
      // })
  
      // If as_cate is true, we first search for the category-ID, to send it with the api
  
  
  
      result = await SearchEntity({
        data: data,
        lang: lang,
        keyword: value_2,
        location: value_1,
        pagination: pages,
        translate: translate,
        firstId: firstId,
        live: live,
        categoryID: categoryID ? categoryID : as_cate ? Number(as_cate) : "",
        as_category: as_cate ? as_cate : false,
        serviceFilter:
          props.location && props.location.search.includes("service=")
            ? filterArray
            : "",
        source: source,
      });
      setResultData({
        entities: result.entities ? result.entities : [],
        error: result.error,
        pagination: result.pagination,
        count: result.count,
        ids: result.ids,
      });
      setLoading(false);
      setHasSearched(false);
    };
  
    // Reason for this function is to get all data in one Array from the API
    // and load in the markers in map view
    // Scroll to the enterprise card of the selected marker (map view only)
    const scrollTo = (e) => {
      const id = document.getElementById(e.replace(/\./g, "_"));
      let allCards = document.querySelectorAll(".card");
      for (let i = 0; i < allCards.length; i++) {
        allCards[i].classList.remove("active");
      }
      id.classList.add("active");
  
      const position = id.offsetParent.offsetTop;
      window.scrollTo({
        top: position - 95,
        behavior: "smooth",
      });
    };
  
    const mapScroll = () => {
      let map = document.getElementsByClassName("resultsMap");
      if (map.length > 0) {
        let viewHeight = document.getElementsByClassName("App")[0].clientHeight;
        let maxHeight = viewHeight - 50;
        let startHeight = viewHeight - 239;
        let scrollOffset = document.body.scrollTop;
        let newHeight = startHeight + scrollOffset;
  
        if (newHeight < maxHeight) {
          if (map) map[0].style.height = newHeight;
        } else if (newHeight > maxHeight) {
          if (map) map[0].style.height = maxHeight;
        }
      }
    };
  
  
    let live = useQuery().get("live") === "true";
    let serviceFilters = useQuery().get("service");
  
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
  
  
    let params = useQuery().get("category");
  
  
    const fetchAds = async () => {
      const banners = await fetchAdvertisements(value_1, value_2);
      setAdvertisementBanners(banners);
      setLoadingAds(false);
    };
  
  
    useEffect(() => {
  
      fetchAds();
    }, [value_1, value_2]);
  
  
  
    useEffect(() => {
      logoScroll();
      window.addEventListener("scroll", logoScroll);
      if (serviceFilters) {
        dispatch(updateFilter(serviceFilters.split(",")));
      }
  
      if (params === null) {
        store.set("osn-cat", "");
      }
      setIsMounted(true);
      if (store.get("osn-cat")) {
        dispatch(updateCategory(store.get("osn-cat")));
        dispatch(updateAsCateogy(store.get("osn-cat")));
      } else if (as_cate) {
        dispatch(updateCategory(as_cate));
        dispatch(updateAsCateogy(as_cate));
        store.set("osn-cat", as_cate);
      }
  
      store.set("prevPath", props.location.pathname + props.location.search);
  
      const getLocation = async (type) => {
        let result = [];
        let as_model = require(`../../models/LocationModal`).default;
        let option = {
          query: {
            bool: {
              must: {
                match_phrase: {
                  slug: {
                    query: value_1.toLowerCase(),
                    slop: 0,
                  },
                },
              },
            },
          },
        };
        if (value_1.toLowerCase() === "berchem" || type === "submuni") {
          option = {
            query: {
              bool: {
                must: {
                  match_phrase: {
                    "submunicipality_set.slug": {
                      query: value_1.toLowerCase(),
                      slop: 0,
                    },
                  },
                },
              },
            },
          };
        }
        await axios
          .get(`${process.env.REACT_APP_BASE_MEDIA_URL2}municipalities/_search`, {
            auth: {
              username: `${process.env.REACT_APP_ESUSERNAME}`,
              password: `${process.env.REACT_APP_ESPSW}`,
            },
            params: {
              source_content_type: "application/json",
              source: JSON.stringify(option),
            },
            cancelToken: source.token,
          })
          .then((resp) => {
            if (resp) {
              // console.log(resp);
              if (resp.data.hits.hits.length === 0) {
                getLocation("submuni");
              } else {
                let selected_item = "";
                for (let i = 0; i < resp.data.hits.hits.length; i++) {
                  let item = resp.data.hits.hits[i]._source;
                  if (value_1.toLowerCase() === item.slug) {
                    selected_item = item;
                  }
                  for (let j = 0; j < item.submunicipality_set.length; j++) {
                    let child = item.submunicipality_set[j];
                    if (child.slug === value_1.toLowerCase()) {
                      selected_item = item;
                    }
                  }
                }
                // let json = resp.data.hits.hits[0]._source;
                let json = selected_item;
                result = as_model(translate, json, lang, value_1);
                dispatch(emptyLocationSelection());
                dispatch(addToLocationSelection(result));
                getData(live, serviceFilters && serviceFilters, result);
              }
            }
          })
          .catch((err) => console.log(err));
        // const result = await searchLocation({
        //   translate: translate,
        //   name: value_1,
        //   lang: lang,
        //   excact: false,
        // });
        // console.log(result);
      };
  
      if (_isMounted) {
        let expire_date = new Date().getTime() + 86400000;
        // let expire_date = new Date().getTime() + 10000;
        // let live = props.match && props.match.params.id === 'live' ? true : false
        if (live) {
          store.set("livePreview", true, expire_date);
          // getData(live, serviceFilters && serviceFilters);
          // getLocation()
        } else {
          // getData(store.get("livePreview"), serviceFilters && serviceFilters);
          getLocation();
        }
        // console.log("isLive:", store.get("livePreview"));
      }
      switchViewListener(true);
      dispatch(updateSearch("keyword", value_2.replaceAll('%20', ' ').replaceAll('%2F', '/')));
  
      window.addEventListener("scroll", mapScroll);
      return () => {
        window.removeEventListener("scroll", logoScroll);
        window.removeEventListener("scroll", mapScroll);
        switchViewListener(false);
        setIsMounted(false);
      };
      // }, [props.match]);
    }, [
      props.match.params,
      _isMounted,
      dispatch,
      lang,
      live,
      serviceFilters,
      translate,
      value_1,
      value_2,
    ]);
  
    // When we search for the second time, the "allResults" array needs to be cleared first, before we let new data load in
    const emptyResults = () => {
      // let copy = [...allResults]
      // copy = []
      // setAllResults(copy)
      setAllResults([]);
      setLoadingMap(true);
    };
  
    // Collect the data given from businessCard and store them into one array
    // Then we can send one array to the map module to load the markers
    const setResults = (data, index) => {
      // let copy = [...allResults]
      // console.log(copy)
      // copy.push(data)
      // setAllResults(copy)
      let copy = allResults;
      let newData = { data: data, index: index };
  
      copy.push(newData);
      if (resultData.ids.length === copy.length) {
        let sortedResults = copy.slice().sort(function (a, b) {
          if (a.index > b.index) {
            return 1;
          }
          if (a.index < b.index) {
            return -1;
          } else {
            return 0;
          }
        });
  
        let newBounds = latLngBounds(); // seemed to work without having to pass init arg
  
        sortedResults.forEach((coords) => {
          newBounds.extend([coords.data.address.lat, coords.data.address.long]);
        });
        setBounds(newBounds);
        setAllResults(sortedResults);
        setLoadingMap(false);
      } else {
        setAllResults(copy);
      }
    };
    // Get the mapData from the package
    const handleMapSettings = (e) => {
      setMapSettings(e);
      setOriginalLatLong(e.getCenter());
      setOriginalZoom(e.getZoom());
    };
  
    // This handles the zoom when you click on a businessCard while on map view(1)
    const setLongLat = (index, e, x) => {
      if (e.data.id === currentEntity.data.id && zoomed) {
        setZoomed(false);
        // This variable is send with map component and is used to set the back button active or inactive
        mapSettings.flyTo(originalLatLong, originalZoom, { duration: 0.5 });
      } else if (zoomed === true && e.id !== currentEntity.data.id) {
        let latlong = { lat: e.data.address.lat, lng: e.data.address.long };
        setCurrentEntity(e);
        setTimeout(() => {
          mapSettings.setView(latlong, 16);
        }, 600);
      } else {
        setZoomed(true);
        // This variable is send with map component and is used to set the back button active or inactive
        setCurrentEntity(e);
        let latlong = { lat: e.data.address.lat, lng: e.data.address.long };
        mapSettings.setView(latlong, 16);
        scrollTo(e.data.id);
      }
    };
  
    const logoScroll = () => {
      let logo = document.getElementById("topLogo");
      let backButton = document.getElementById("marker_back");
      if (backButton) {
        if (window.scrollY > 157) {
          backButton.style.top = `75px`;
        } else {
          backButton.style.top = `15px`;
        }
      }
      if (window.scrollY >= 40) {
        logo.style.opacity = 1;
        logo.style.display = "flex";
      } else {
        logo.style.opacity = 0;
        logo.style.display = "none";
      }
    };
  
    return (
      <div
        className="Environment"
        style={{ filter: map.show ? "blur(5px)" : null }}
      >
        <Redirects {...props} page="result" />
        {/* Insert meta tags */}
        <Meta title={translate("your_search_results")} />
  
        {/* Show loading screen if api call is still busy */}
        {/* {loading && <div className="loadingScreen"><Plenny /></div>} */}
  
        {/* Insert navigation */}
        <Navigation {...props} background />
        <div id="results" tabIndex="0">
          <div
            className="topSearchBar"
            style={{ position: "sticky", display: "flex" }}
          >
            <Link className="navLogo" id="topLogo" to="/">
              <img
                alt={translate("esn_alt")}
                className="logo logoOriginal"
                src={require(`../../library/imgs/logos/esn/logo_esn_small_${lang}.svg`)}
                height="25px"
                width="25px"
              />
            </Link>
            <div className="topSearchBarWrapper">
              <Suspense fallback={<div></div>}>
                <AlternateSearchBar
                  size="small"
                  switch_view={(e) => switchViewListener(e)}
                  location_value={value_1}
                  emptyMarkers={() => emptyResults()}
                  page="results"
                  loading={loading}
                  hasSearched={(e) => setHasSearched(e)}
                // options
                />
              </Suspense>
            </div>
          </div>
          {/* Don't show number of results when loading */}
          {!loading && (
            <div
              className="resultsTop"
              style={{ padding: view === 1 ? "0 25px" : "" }}
            >
              {resultData.pagination.total_pages > 1 && (
                <React.Fragment>
                  <b>
                    {translate("page")} {resultData.pagination.current_page} -{" "}
                    {resultData.pagination.total_pages}:{" "}
                  </b>
                  {translate("with") + " "}
                </React.Fragment>
              )}
              {resultData.count}{" "}
              {translate(
                resultData.entities.length > 1 || resultData.entities.length === 0
                  ? "results"
                  : "result"
              )}
            </div>
          )}
          <div className={`enterprisesWrapper ${view === 1 ? "mapView" : ""}`}>
            {!resultData.error && !loading && view === 1 && (
              <div className={`resultsMap`}>
                {!loadingMap && (
                  <MapMarker
                    addresses={allResults}
                    getMap={(e) => handleMapSettings(e)}
                    scrollTo={(e) => scrollTo(e)}
                    zoomed={zoomed}
                    setZoomed={(e) => setZoomed(e)}
                    bounds={bounds}
                  />
                )}
                {view === 1 && (
                  <Pagination
                    path={(page) =>
                      path(
                        value_1,
                        value_2,
                        page,
                        "",
                        as_cate && as_cate,
                        serviceFilters && serviceFilters
                      )
                    }
                    pages={resultData.pagination}
                  />
                )}
              </div>
            )}
            <div className="resultsList">
              {hasSearched && (
                <div id="patientMessage">
                  Even geduld, we halen jouw vorige resultaten op!{" "}
                  <Icon onClick={() => setHasSearched(false)} name="Close" />
                </div>
              )}
              {
                // If error, show error message to the user
                resultData.error ? (
                  <div className="errorMessage">
                    <span>{translate(resultData.error)}</span>
                  </div>
                ) : // If no error has ocurred and the pages are filled, render the results
                  !loading ? (
                    <React.Fragment>
                      <Suspense
                        fallback={
                          <div className="loadingScreen">
                            <Icon name="Loading" animated />
                          </div>
                        }
                      >
                        {resultData.ids.map((entity, index) => {
                          return (
                            <React.Fragment key={index}>
                              <div
                                className="cardWrapper"
                                onClick={() => setActiveId(entity.id)}
                              >
                                <BusinessCard
                                  live={props.match && props.match.params.id === "live"}
                                  activeId={activeId}
                                  entity={entity}
                                  index={index}
                                  prevPath={path(
                                    value_1,
                                    value_2,
                                    resultData.pagination.current_page,
                                    as_cate && as_cate,
                                    serviceFilters && serviceFilters
                                  )}
                                  id={entity.id}
                                  getResults={(data) => setResults(data, index)}
                                  setActiveId={(e) => setActiveId(e)}
                                  setLongLat={(e) =>
                                    setLongLat(index, allResults[index], "card")
                                  }
                                />
                              </div>
                              
                              {/* Show ad after every 5th result */}
                              {!is100ProcentLokaal && (index + 1) % 5 === 0 &&
                                index + 1 !== resultData.ids.length &&
                                advertisementBanners[Math.floor((index + 1) / 5) - 1] && (
                                  <div className="advertisementBannerMobile">
                                    <AdvertisementCard
                                      banner={advertisementBanners[Math.floor((index + 1) / 5) - 1]}
                                    ></AdvertisementCard>
                                  </div>
                                )
                              }
  
                            </React.Fragment>
                          );
                        })}
                        {view === 0 && resultData.pagination.total_pages > 1 && (
                          <Pagination
                            path={(page) =>
                              path(
                                value_1,
                                value_2,
                                page,
                                "",
                                as_cate && as_cate,
                                serviceFilters && serviceFilters
                              )
                            }
                            pages={resultData.pagination}
                          />
                        )}
                      </Suspense>
                    </React.Fragment>
                  ) : (
                    <div className="loadingScreen">
                      <Icon name="Loading" animated />
                    </div>
                  )
              }
            </div>
            {/* Conditionally render vertical line and advertisements */}
            {!is100ProcentLokaal && advertisementBanners.length > 0 && !loadingAds && (
              <>
                <div className="verticalLine"></div>
                <div className="advertisementBannersForDesktop">
                  {advertisementBanners.map((ad, index) => (
                    <AdvertisementCard key={index} banner={ad} />
                  ))}
                </div>
              </>
            )}
          </div>
        </div >
        <Footer />
      </div >
    );
  };
  
  export default AlternateResultsPage;
  