
import { emptyLocationSelection, addToLocationSelection } from "../../actions";
import { useSelector, useDispatch } from "react-redux";
import React, { useEffect, useState } from "react";
import { Redirect } from "react-router";
import { searchLocation } from "../../services/index"
import store from 'store'
import Axios from "axios";

const Redirects = (props) => {
    const dispatch = useDispatch();
    const { lang, nis, translate } = useSelector(state => state.general)
    const [path, setPath] = useState(false);
    let id = props.match && props.match.params.id ? props.match.params.id : '';
    let mod = props.match && props.match.params.mod ? props.match.params.mod : '';

    
    
    useEffect(() => {
        if (props.location.pathname.includes('/in/') || props.location.pathname.includes('/naar/')) {
            setPath(props.match.url.replace('in/', '').replace('naar/', '') + props.location.search)

        }
        const getData = async () => {
            // add name when url exist with only ID's, that's why I make an API call to get the enterprisename
            const name = await Axios.get(`https://api.ondernemersnetwerk.be/public/get/${id}?info=names&lang=${lang}`)
                .then((res) => {
                    return res.data.company_name
                })
            if (!props.match.params.name) {
                setPath(`/business/${id}/${name}/${mod ? mod : 'contact'}`);
            }
            if (props.page === 'bp' && !props.match.params.mod) {
                setPath(`/business/${id}/${name}/contact`);
            }
        }
        
        if (!props.match.params.name && props.match.params.id) { getData(); }
        if (!props.match.params.mod && props.page && props.page === 'bp') { getData(); }

        // props.match.params.id && getData();
        let page = props.match && props.match.params.page ? props.match.params.page : "";
        let search_slug = translate("search_slug", lang);
        let page_slug = translate("page", lang);
        let value_1 = props.match && props.match.params.value_1 ? props.match.params.value_1.replace(/\+/g, " ") : "";
        let value_2 = props.match && props.match.params.value_2 ? props.match.params.value_2.replace(/\+/g, " ") : "";

        // remove the "-1" from the slug to match value_1 with getLocation data 
        value_1 = value_1.replace("-1", '')
        value_1 = value_1.replace("-2", '')
        value_1 = value_1.replace("â", "a")

        // business page
        // if (props.page === 'bp') {
        //     getData();
        // }



        //    resultpage on nis version, redirect when the entered location does not match nis or submunicipalities from nis
        const getLocation = async () => {
            let data = []
            const result = await searchLocation({ translate: translate, name: nis, lang: lang, excact: true })
            if (result) {
                data.push(result.name.toLowerCase().replace(/-[-[1,]]*/, '').replace(/ /g, '-').replace("â","a"))
                for (let i = 0; i < result.children.length; i++) {
                    let child = result.children[i]
                    data.push(child.slug.replace(/-[-[1,]]*/, '').replace(/ /g, '-').replace("â","a"))
                }
                // .replace(/\./g, '')}
                if (!data.includes(value_1.replace(/-[-[1,]]*/, '').replace(/ /g, '-'))) {
                    dispatch(emptyLocationSelection())
                    dispatch(addToLocationSelection(result))
                    setPath(`/`)
                }
            }
        }
        setTimeout(() => {
            nis && props.page === 'result' && getLocation();
        }, 400)

        if (page) {
            if (props.match.params.id === 'live') {
                props.match && props.match.params.live && store.set('livePreview', true, new Date().getTime() + 1)
                // setPath(props.match.url.replace('/live', ''))}
                // Use this instead of setPath because setPath doesn't store the cookie 
                // window.location.href = props.match.url.replace('/live', '')
            }
            if (props.match.path === `/${search_slug}/:value_1?/${page_slug}=:page?`) {
                if (!value_1) setPath(`/${search_slug}/${page_slug}=${page}`);
            }
            else if (props.match.path === `/${search_slug}/:value_2?/${page_slug}=:page?`) {
                if (!value_2) setPath(`/${search_slug}/${value_2}/${page_slug}=${page}`);
            }
            else if (props.match.path === `/${search_slug}/:value_1?/:value_2?/${page_slug}=:page?`) {
                if (!value_1 && value_2) setPath(`/${search_slug}/${value_2}/${page_slug}=${page}`);
                else if (value_1 && !value_2) setPath(`/${search_slug}/${value_1}/${page_slug}=${page}`);
                else if (!value_1 && !value_2) setPath(`/${search_slug}/${page_slug}=${page}`);
            }

        }
        else {
            if (props.match.path === `/${search_slug}/:value_2?`) {
                if (!value_2) setPath(`/${search_slug}/${value_2}/${page_slug}=${page}`);
            }
            else if (props.match.path === `/${search_slug}/:value_1?/:value_2?`) {
                setPath(`/${search_slug}/${value_1}/${value_2}/${page_slug}=1?`);
            }
            else if (props.match.path === `/${search_slug}/:value_1?/:value_2?/:id?`) {
                setPath(`/${search_slug}/${value_1}/${value_2}/${page_slug}=1?`);

            }
            else if (props.match.path === `/${search_slug}/:value_1?`) {
                setPath(`/${search_slug}/${value_1}/${page_slug}=1?`);
            }
            else if (props.match.path === `/${search_slug}`) {
                setPath(`/${search_slug}/${page_slug}=1?`);
            }
        }

    }, [dispatch, lang, nis, props, translate, id, mod])

    return (
        <React.Fragment>
            {path && <Redirect to={path} />}
        </React.Fragment>
    )
}

export default Redirects;