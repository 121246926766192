
const DetailsModel = (json, api_uri, lang) => {
    let data = {};
    data.business_entity_id = json.business_id ? json.business_id : ''
    // Enterprise ID's
    data.id = json.business_id ? json.business_id : "";
    data.atmaker_id = json.atmaker_id ? json.atmaker_id : ""
    data.has_subscription = json.has_subscription ? json.has_subscription : false
    data.css_path = ''
    data.main_color = ''

    // Denominations
    data.enterprise_name = "";
    data.establishment_name = "";
    data.trade_name = "";

    // Address information
    data.address = {};

    // Contact information
    data.qr = `${api_uri}/public/business/${data.business_entity_id}/qr.png`;
    data.vcard = `${api_uri}/public/business/${data.business_entity_id}/vcard`;
    data.telephone = "";
    data.cellphone = "";
    data.fax = "";
    data.website = "";
    data.social_media = []
    data.contacts = []
    data.email = ""
    data.registration_numbers = []

    // Business page specific information
    data.facilities = []
    data.cmps = [];
    data.cmp_collections = [];
    data.cread_collections = [];
    data.openinghours = [];
    data.brandslist = [];
    data.activities = [];
    data.videolibrary = [];


    // check if opening hours gets received from the convincing letter
    data.opening_hours_reveived = false


    // Business page activation
    data.business = {
        page: true,
        creads: data.cread_collections.length > 0,
        videos: data.videolibrary.length > 0,
        cmp: data.cmp_collections.length > 0,
        brands: data.brandslist.length > 0,
        activities: data.activities.length > 0,
        contact: {
            openinghours: false,
            facilities: false,
            contacts: false
        }
    };


    return data;
}

export default DetailsModel;