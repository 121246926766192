const AutoSuggestionKeywordModel = (json, type, lang) => {
  let data = {};
  data.id = json.code;
  data.text = json[`name_${lang}`] || json.name_nl || json.name_fr;
  data.type = type;

  if (
    json.icon.png_nl !== undefined ||
    json.icon.png_fr !== undefined ||
    json.icon.png_de !== undefined ||
    json.icon.png_en !== undefined
  ) {
    data.icon =
      json.icon[`png_${lang}`] ||
      json.icon.png_nl ||
      json.icon.png_fr ||
      json.icon.png_de ||
      json.icon.png_en;
  } else {
    data.icon = "";
  }

  return data;
};

export default AutoSuggestionKeywordModel;
