import { Button, OsnAlertBox } from "@identitybuilding/idb-react-ui-elements";
import { Link } from "react-router-dom";
import {
  addToLocationSelection,
  emptyLocationSelection,
  toggleMap,
  updateSearch,
  toggleView,
  updateAsCateogy,
  updateCategory,
} from "../../actions";

import { useDispatch, useSelector } from "react-redux";
import { AutoSuggestionKeyword } from "../../services";
import Icon from "@identitybuilding/idb-react-iconlib";
// import Keyboard from "react-simple-keyboard";
import React, { useEffect, useRef, useState, lazy, Suspense } from "react";
// import SearchAutoSuggestion from "./SearchAutoSuggestion";
// import SearchLocationTag from "./SearchLocationTag";
// import SearchOptions from "./SearchOptions";
// import SearchLastCriteria from "./SearchLastCriteria";
// import SearchMunicipalitySelection from "./SearchMunicipalitySelection";
// import SearchFilter from './SearchFilter'

import store from "store";
import { useLocation, useHistory } from "react-router-dom";
import { isMobileOnly } from "react-device-detect";
import Plausible from "plausible-tracker";
import axios from "axios";

let keyInput = [];

const AlternateSearchFilter = lazy(() => import("./AlternateSearchFilter.js"));
const Keyboard = lazy(() => import("react-simple-keyboard"));
const SearchMunicipalitySelection = lazy(() =>
  import("./SearchMunicipalitySelection")
);
const SearchLastCriteria = lazy(() => import("./SearchLastCriteria"));
const SearchOptions = lazy(() => import("./SearchOptions"));
const SearchLocationTag = lazy(() => import("./SearchLocationTag"));
const AlternateSearchLocationTag = lazy(() => import("./AlternateSearchLocationTag"));
const SearchAutoSuggestion = lazy(() => import("./SearchAutoSuggestion"));

// const plausible = Plausible({
// 	domain: 'osntest.be',
// 	track100procentlokaal: false
// })

const { trackEvent } = Plausible();

const AlternateSearchBar = ({
  props,
  location_value,
  options,
  size = "large",
  switch_view,
  page,
  loading,
  hasSearched,
}) => {
  const [type, setType] = useState({
    keyword: {
      index: 0,
      show: false,
      suggestion: [],
    },
    location: {
      empty: false,
      index: 0,
      show: false,
      show_tag: false,
      suggestion: [],
    },
  });

  let vks = useRef();
  let node_keyword = useRef();
  let node_location = useRef();

  const [alert, setAlert] = useState(false);
  const [windowTarget, setWindowTarget] = useState();
  const [typingTimeout, setTypingTimeout] = useState(0);
  const [showKeyboard, setShowKeyboard] = useState(false);
  const [activeType, setActiveType] = useState(false);
  const [layout, setLayout] = useState("default");
  let view = useSelector((state) => state.filter.view);
  let categoryID = useSelector((state) => state.search.as_category);
  let serviceFilter = useSelector((state) => state.search.serviceFilter);
  const { location } = useSelector((state) => state.search);

  let expire_date = new Date().getTime() + 300000;

  const dispatch = useDispatch();




  // get the two values out of the url parameters only when they are given
  // let value_1 = props && props.match && props.match.params.id ? props.match.params.id.replace(/\+/g, " ") : "";
  let value_2 =
    props && props.match && props.match.params.value
      ? props.match.params.value.replace(/\+/g, " ")
      : "";

  let { device, lang, nis, translate } = useSelector((state) => state.general);
  let search = useSelector((state) => state.search);
  let path = useSelector((state) => state.general.search_path);

  const keyboard = store.get("vkb");
  // useEffect(() => {
  //   console.log("Updated search location:", search.location);
  // }, [search.location]);

  useEffect(() => {
    // page !== 'results' && removeAsCategory()
    page === "results" && checkPlausible("landing");
    const getParamerters = async () => {
      // when two arguments are given, put value_1 in keyword selection
      value_2 && dispatch(updateSearch("keyword", value_2));
    };
    const handleClick = (e) => {
      let nodes = {
        keyword: node_keyword,
        location: node_location,
      };

      let type = e.target
        ? e.target.id === "location_input"
          ? "location"
          : e.target.id === "keyword_input"
            ? "keyword"
            : ""
        : "";
      if (
        (type && nodes[type].current.contains(e.target)) ||
        vks.current.keyboardDOM.contains(e.target)
      ) {
        setShowKeyboard(true);
      } else {
        let index = type.locatoin && type.location.index;
        setShowKeyboard(false);

        // set the selected location when clicking somewhere else
        // console.log(type.location)
        type.location &&
          dispatch(
            updateSearch(
              "location",
              type.location && type.location.suggestion[index]
            )
          );
      }
    };

    getParamerters();
    keyboard && document.addEventListener("click", handleClick);
    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, [keyboard, dispatch, value_2]);

  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };
  const hist = useHistory();
  const querry = useQuery();

  const removeAsCategory = () => {
    // hist.push({category: querry.delete('category')})
  };

  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  const onChange = (rawValue, y) => {
    let value = rawValue
      .replace(/è/g, "e")
      .replace(/é/g, "e")
      .replace(/ê/g, "e")
      .replace(/ë/g, "e")
      .replace(/á/g, "a")
      .replace(/à/g, "a")
      .replace(/â/g, "a")
      .replace(/ä/g, "a")
      .replace(/ü/g, "u")
      .replace(/û/g, "u")
      .replace("(stad)", "")
      .replace("(ville)", "")
      .replace("(stadt)", "")
      .replace("city", "")
    // .replace(" ", "");

    let host = window.location.hostname.replace(/^[^.]*\.(?=\w+\.\w+$)/, "");
    if (keyboard) vks.current.setInput(value);
    // To avoid launching API calls after every time the user types a keyword,
    // we use a timeout to check when the user has stopped typing
    if (typingTimeout) clearTimeout(typingTimeout);

    if (y === "keyword") {
      dispatch(updateSearch("keyword", ""));
      dispatch(updateAsCateogy(false));
      dispatch(updateCategory(""));
      removeAsCategory();
      store.remove("osn-cat");
      dispatch(updateAsCateogy(false));
    }

    dispatch(updateSearch(y, rawValue));

    // canceling request when new one has been made and old hasn't been resolved
    // Documentation: https://github.com/axios/axios#cancellation
    // Implementation: https://stackoverflow.com/questions/62775964/how-do-i-cancel-a-ongoing-get-request-with-axios/62776102#62776102
    setTimeout(async () => {
      source.cancel();
    }, 1000);
    setTypingTimeout(
      setTimeout(async () => {
        if (value) {
          let result = [];
          let as_model =
            require(`../../models/AutoSuggestionLocationModel`).default;

          let stringFields = [
            `name_nl`,
            `name_fr`,
            `name_de`,
            `name_en`,
            `alt_name_nl`,
            `alt_name_fr`,
            `submunicipality_set.alt_name_fr`,
            `submunicipality_set.alt_name_nl`,
            `submunicipality_set.name_nl`,
            `submunicipality_set.name_fr`,
            `submunicipality_set.name_de`,
            `submunicipality_set.name_en`,
            `nis_code`,
            `nis_code_1970`,
            `nis_code_1977`,
            `submunicipality_set.nis_code_1970`,
            `submunicipality_set.nis_code_1977`,
          ];

          let numberFields = [
            "pkancode",
            "nis_code",
            "nis_code_1970",
            "nis_code_1977",
            "submunicipality_set.pkancode",
            "submunicipality_set.nis_code_1970",
            "submunicipality_set.nis_code_1977",
          ];

          if (y === "keyword") {
            result = await AutoSuggestionKeyword({ value: value, lang: lang });
          } else {
            // if (!value.includes(" "))
            let option = {};
            if (isNaN(value)) {
              option = option = {
                from: 0,
                size: 581,
                query: {
                  bool: {
                    should: [
                      {
                        query_string: {
                          query: `\"${value}\"`,

                          fields: stringFields,
                          tie_breaker: 0.3,
                        }
                      },
                      {
                        query_string: {
                          query: `*${value}*`,

                          fields: stringFields,
                          tie_breaker: 0.3,
                        }
                      },
                      {
                        query_string: {
                          query: `${value}`,

                          fields: stringFields,
                          tie_breaker: 0.3,
                        }
                      }
                    ]
                  }

                },
                sort: [
                  // {
                  //   pkancode: {
                  //     order: "asc",
                  //   },
                  // },
                ],
              };
            } else {
              option = {
                size: 581,
                query: {
                  query_string: {
                    query: `${value}`,
                    fields: numberFields,
                    tie_breaker: 0.3,
                  },
                },
                sort: [
                  // {
                  //   pkancode: {
                  //     order: "asc",
                  //   },
                  // },
                ],
              };
            }
            await axios.get(
              `${process.env.REACT_APP_BASE_MEDIA_URL2}municipalities/_search`,
              {
                auth: {
                  username: `${process.env.REACT_APP_ESUSERNAME}`,
                  password: `${process.env.REACT_APP_ESPSW}`,
                },
                params: {
                  source_content_type: "application/json",
                  source: JSON.stringify(option),
                },
              }
            )
              .then((resp) => {
                if (resp) {
                  if (resp.data.hits.hits.length === 0) {
                    // let option = {};
                    if (isNaN(value)) {
                      option = {
                        from: 0,
                        size: 581,
                        query: {
                          query_string: {
                            query: `${value}`,
                            fields: stringFields,
                            tie_breaker: 0.3,
                          },
                        },
                        sort: [
                          // {
                          //   pkancode: {
                          //     order: "asc",
                          //   },
                          // },
                        ],
                      };
                    } else {
                      option = {
                        from: 0,
                        size: 581,
                        query: {
                          query_string: {
                            query: `${value}`,
                            fields: stringFields,
                            tie_breaker: 0.3,
                          },
                        },
                        sort: [
                          // {
                          //   pkancode: {
                          //     order: "asc",
                          //   },
                          // },
                        ],
                      };
                    }
                    axios.get(
                      `${process.env.REACT_APP_BASE_MEDIA_URL2}municipalities/_search`,
                      {
                        auth: {
                          username: `${process.env.REACT_APP_ESUSERNAME}`,
                          password: `${process.env.REACT_APP_ESPSW}`,
                        },
                        params: {
                          source_content_type: "application/json",
                          source: JSON.stringify(option),
                        },
                        cancelToken: source.token,
                      }
                    )
                      .then((resp) => {
                        if (resp) {
                          let data = resp.data.hits.hits;
                          result = as_model(data, lang, translate, value);
                          let copy = { ...type };
                          copy[y].suggestion = result;
                          setType(copy);
                        } else {
                          let copy = { ...type };
                          copy[y].suggestion = "";
                          setType(copy);
                        }
                      })
                      .catch((err) => console.log(err));
                  }
                  let data = resp.data.hits.hits;
                  result = as_model(data, lang, translate, value);
                }
              })
              .catch((err) => console.log(err));
          }
          let copy = { ...type };
          copy[y].suggestion = result;
          setType(copy);
        } else {
          let copy = { ...type };
          copy[y].suggestion = "";
          setType(copy);
        }
      }, 500)
    );
    keyInput = document.getElementsByClassName("autosuggestion");
  };

  // When focusing or blurring out of the input field,
  // the autosuggestion is either hidden or shown
  const onFocusBlur = (x, action) => {
    let copy = { ...type };
    let keyClass = "";
    copy.keyword.show = true;

    window.addEventListener("click", (e) => {
      if (e.target.className !== "") {
        keyClass = e.target.className;
      }

      if (keyClass === "as as_text active" || keyClass === "as as_text") {
        copy.keyword.show = "keyword";
      } else {
        copy.keyword.show = true;
      }

      if (x || type.keyword.show || type.location.show) {
        copy.keyword.show = x === "keyword";
        copy.location.show = x === "location";
        setType(copy);
      }
    });
  };

  // Key action to save value of location input field
  const keyDownHandler = (x, y) => {
    let suggestion = type[y].suggestion,
      suggestion_index = type[y].index,
      suggestion_item = suggestion[suggestion_index];

    /* 
      When pressing enter or tab, we want to act depending on multiple aspects:
      - Is the autosuggestion active or not?
      - Is the value empty?
      - ...
    */
    if (x.keyCode === 13 || x.keyCode === 9) {
      if (y === "keyword") {
        // if user presses tab while on keyword input,
        // It will select the selected item from the autosuggestion
        if (x.keyCode === 9) {
          suggestion &&
            suggestion_item &&
            dispatch(updateSearch("keyword", suggestion_item.text));
        }
        if (x.keyCode === 13) {
          // If the user presses enter while on keyword input,
          // we first check if we has selected locations
          if (search.location.length === 0) {
            let copy = { ...type };
            copy.location.empty = true;
            setType(copy);
            x.preventDefault();
          }
          if (type.keyword.show) {
            let copy = { ...type };
            copy[y].show = false;
            onFocusBlur(false);
            setType(copy);
          }
        } else if (x.keyCode === 9 && x.keyCode === 13) {
          onFocusBlur(false);
        }
      }

      // When searching on location, we prevent the default behavior of the form
      // as long as the user hasn't selected any locations
      else if (y === "location" && !nis) {
        let copy = { ...type };
        copy.location.empty =
          x.target.value === "" && search.location.length === 0;
        copy.location.suggestion = suggestion.length > 0 ? [] : suggestion;
        setType(copy);
        // If location input value is not empty
        if (suggestion.length > 0) {
          dispatch(addToLocationSelection(suggestion_item));

          // Upon choosing your location with enter, start searching.
          // This if statement makes sure you don't search when you are already on that page
          if (
            window.location.pathname !==
            path(
              suggestion_item && suggestion_item.slug,
              search.keyword_value,
              1,
              keyboard,
              categoryID && categoryID,
              serviceFilter
            )
          ) {
            window.location.href = path(
              suggestion_item && suggestion_item.slug,
              search.keyword_value,
              1,
              keyboard,
              categoryID && categoryID,
              serviceFilter
            );
          }
        }

        // Prevent default submit behaviour if there is no location
        // or if the user is selecting a location from the auto suggestion
        if (
          (search.location.length === 0 && x.keyCode === 13) ||
          suggestion.length > 0
        ) {
          x.preventDefault();
        }
      }
    }

    // Moves the active selected auto suggestion list item
    // using the up- and down arrowkeys
    else if (x.keyCode === 38 || x.keyCode === 40) {
      let myType = type.keyword.show
        ? "keyword"
        : type.location.show
          ? "location"
          : "",
        suggestion = type[myType].suggestion,
        state = type[myType].index;
      if (suggestion.length > 0) {
        let i =
          x.keyCode === 38
            ? state > 0
              ? state - 1
              : suggestion.length - 1
            : x.keyCode === 40 && state < suggestion.length - 1 && state + 1;

        // Move the autosuggestion scrollbar with the active selected item
        let autosuggestion = document.getElementsByClassName(
          "autosuggestion_content"
        ),
          active_item = document.getElementsByClassName("as_text");
        if (autosuggestion.length > 0 && active_item.length > 0)
          autosuggestion[0].scrollTop = !i ? 0 : active_item[i].offsetTop - 7;
        if (i >= 0) {
          let copy = { ...type };
          copy[myType].index = i ? i : 0;
          setType(copy);
        }
      }
    }

    // If there is no value - which means there is only a location tag -
    // remove the location tag on backspace
    else if (x.keyCode === 8) {
      if (y === "location")
        if (!search.location_value) dispatch(emptyLocationSelection());
    }
  };
  // Location value is required
  const searchBtnHandler = (e) => {
    if (loading) {
    } else {
      if (search.location.length === 0) {
        let copy = { ...type };
        copy.location.empty = true;
        setType(copy);
        e.preventDefault();
      } else {
        let cookie_pref = store.get("cookie_pref");
        if (cookie_pref) {
          if (cookie_pref.acceptance) {
            let search_criteria = store.get("searchCriteria");
            let storeSearch = [];
            let duplicate = false;

            if (search_criteria) {
              if (search_criteria.length > 0) {
                let copy = [...search_criteria];
                storeSearch = copy;
              }
            }

            for (let i = 0; i < storeSearch.length; i++)
              if (
                storeSearch[i].keyword === search.keyword_value &&
                storeSearch[i].location[0].slug === search.location[0].slug
              )
                duplicate = true;

            if (duplicate === false) {
              // If search criteria is up to 3 items already, removed the first one
              if (storeSearch.length >= 3) storeSearch.splice(0, 1);
              storeSearch.push({
                keyword: search.keyword_value,
                location: search.location,
                categoryID: categoryID,
              });
            }
            store.set("searchCriteria", [...storeSearch]);
          }
        }
      }
    }
  };
  // console.log(store.get("searchCriteria"));
  const inputActions = (x) => {
    return {
      // Activates switch view on results page and on blur
      onBlur: () => {
        onFocusBlur(false);
        switch_view && switch_view(true);
        // if (type[x].suggestion.length > 0) {
        // 	console.log(type[x].suggestion[type[x].index])
        // 	dispatch(emptyLocationSelection())
        // 	dispatch(updateSearch(x, type[x].suggestion[type[x].index]))
        // }
      },
      onChange: (e) => {
        onChange(e.target.value, x);
      },
      // Deactivates switch view on results page and on focus
      onFocus: () => {
        onFocusBlur(x);
        switch_view && switch_view(false);
        setActiveType(x);
      },
      onKeyDown: (e) => keyDownHandler(e, x),
      placeholder: translate(`search_${x}`),
      type: "text",
      value: search && search[`${x}_value`],
    };
  };

  const handleShift = () => {
    const newLayoutName = layout === "default" ? "shift" : "default";
    setLayout(newLayoutName);
  };

  const onKeyPress = (button, type) => {
    if (button === "{shift}" || button === "{lock}") handleShift();
    // if (button === "{enter}") keyDownHandler({ keyCode: 13 }, type)
  };

  const testVkbLang = () => {
    const lang = ["french", "english", "japanese", "thai"];

    if (lang.includes(keyboard.vkb)) {
      return keyboard.vkb;
    } else {
      return "french";
    }
  };

  const checkPlausible = (action) => {
    switch (action) {
      case "switch": {
        trackEvent("MapView", {
          props: {
            variation:
              view === 0 ? "Switching to mapView" : "Switching to CardView",
          },
        });
        break;
      }
      case "landing": {
        trackEvent("MapView", {
          props: {
            variation:
              view === 0 ? "Landing on CardView" : "Landing on MapView",
          },
        });
        break;
      }
      default:
    }
  };

  return (
    <div
      className={`searchBarWrapper ${size}`}
      style={{ animation: size === "small" ? null : "searchBarIntro 0.5s" }}
    >
      {keyboard && (
        <div className={`vkeyboard ${showKeyboard ? "active" : ""}`}>
          <Keyboard
            inputName={activeType}
            keyboardRef={(r) => (vks.current = r)}
            layout={
              require(`simple-keyboard-layouts/build/layouts/${testVkbLang()}`)
                .default
            }
            layoutName={layout}
            onChange={(e) => onChange(e, activeType)}
            onKeyPress={(e) => onKeyPress(e, activeType)}
          />
        </div>
      )}
      {alert && (
        <OsnAlertBox
          message={translate("how_to_bookmark")}
          onClick={() => setAlert(false)}
        />
      )}
      <form
        className="searchBar"
      // style={{ animation: size === "small" ? null : "searchBarIntro 0.5s" }}
      >
        {page !== "bp" && (
          <div className="toggleView">
            <Icon
              name={view === 0 ? "ResultsView" : "ResultsMapView"}
              onClick={() => {
                checkPlausible("switch");
                view === 0 ? dispatch(toggleView(1)) : dispatch(toggleView(0));
              }}
            />
            <span>
              {translate(view === 0 ? "view_on_map" : "view_on_results")}
            </span>
          </div>
        )}

        <div className="searchInput">
          <label htmlFor="keyword_input">
            <input
              {...inputActions("keyword")}
              id="keyword_input"
              ref={node_keyword}
              autoComplete="off"
              disabled={loading ? true : false}
            />
          </label>
          {search && search.keyword_value && (
            <div
              className="emptyValue"
              onClick={() => {
                dispatch(updateSearch("keyword", ""));
                dispatch(updateAsCateogy(false));
                dispatch(updateCategory(""));
                removeAsCategory();
                store.remove("osn-cat");
              }}
            >
              <Icon name="CloseCircle" />
            </div>
          )}
          {((showKeyboard && activeType === "keyword") || type.keyword.show) &&
            type.keyword.suggestion.length > 0 && (
              <Suspense fallback={<div></div>}>
                <SearchAutoSuggestion
                  active={type.keyword.index}
                  data={type.keyword.suggestion}
                  type="keyword"
                  value={search ? search.keyword_value : ""}
                  setType
                />
              </Suspense>
            )}
        </div>
        {!nis ? (
          <React.Fragment>
            <div
              className="searchLocation"
              onClick={() => dispatch(toggleMap())}
            >
              <div className="mapMarker">
                <Icon name="Mapmarker" />
              </div>
              <div className="mapMarkerFill">
                <Icon name="MapmarkerFill" />
              </div>
            </div>
            <div
              className={`locationInput searchInput ${type.location.empty ? "empty" : ""
                }`}
            >
              {search && search.location.length > 0 && (
                <Suspense fallback={<div></div>}>
                  <AlternateSearchLocationTag
                    device={device}
                    search={search}
                    translate={(e) => translate(e)}
                    loading={loading}
                    lang={lang}
                  />
                </Suspense>
              )}

              {search.location.length === 0 && (
                <React.Fragment>
                  <label htmlFor="location_input">
                    <input
                      {...inputActions("location")}
                      id="location_input"
                      ref={node_location}
                      autoComplete="off"
                      disabled={loading ? true : false}
                    />
                  </label>
                </React.Fragment>
              )}
              {type.location.suggestion.length > 0 &&
                search.location.length === 0 && (
                  // {((showKeyboard && activeType === "location") || type.location.show) && type.location.suggestion.length > 0 && search.location.length === 0 && */
                  <Suspense fallback={<div></div>}>
                    <SearchAutoSuggestion
                      active={type.location.index}
                      data={type.location.suggestion}
                      type="location"
                      value={search ? search.location_value : ""}
                    />
                  </Suspense>
                )}
            </div>
          </React.Fragment>
        ) : (
          <Suspense fallback={<div></div>}>
            <SearchMunicipalitySelection location_value={location_value} />
          </Suspense>
        )}
        <div className="searchBtn">
          {loading ? (
            <div
              onClick={() => hasSearched(true)}
              title={`${translate("Search")} ${search.location[0] && search.location[0].name
                }`}
            >
              <Icon name="ArrowRight" />
              <Icon name="Search" />
              <label htmlFor="search_button">
                <span className="label">{translate("search")}</span>
                <input
                  id="search_button"
                  type="submit"
                  value={translate("search")}
                />
              </label>
            </div>
          ) : (
            <Link
              onClick={(e) => {
                searchBtnHandler(e);
              }}
              to={path(
                Array.isArray(search.location?.[0])
                  ? search.location?.[0][0]?.slug
                  : search.location?.[0]?.slug,
                search.keyword_value?.replaceAll('/', "%2F"),
                1,
                keyboard,
                categoryID || "",
                serviceFilter || ""
              )}
              title={`${translate("Search")} ${Array.isArray(search.location?.[0])
                  ? search.location?.[0][0]?.name
                  : search.location?.[0]?.name
                }`}

            >
              <Icon name="ArrowRight" />
              <Icon name="Search" />
              <label htmlFor="search_button">
                <span className="label">{translate("search")}</span>
                <input
                  id="search_button"
                  type="submit"
                  value={translate("search")}
                />
              </label>
            </Link>
          )}
          {/* <a
						onClick={(e) => { searchBtnHandler(e); }}
						href={path(search.location[0] && search.location[0].slug, search.keyword_value, 1, keyboard, categoryID && categoryID, serviceFilter)}
						title={`${translate("Search")} ${search.location[0] && search.location[0].name}`}
					>
						<Icon name="ArrowRight" />
						<Icon name="Search" />
						<label htmlFor="search_button">
							<span className='label'>{translate("search")}</span>
							<input id="search_button" type="submit" value={translate("search")} />
						</label>
					</a> */}
        </div>
      </form>
      {options && (
        <React.Fragment>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "5px",
            }}
          >
            <Suspense fallback={<div></div>}>
              <SearchLastCriteria />
            </Suspense>
            {device !== "mobile" && (
              <div className="searchBarBrowerActions">
                <Button
                  icon="Star"
                  onClick={() => setAlert(true)}
                  label={`${translate("add_to_browser")}`}
                  text={`${translate("add_to_browser")}`}
                  type="regular"
                />
              </div>
            )}
          </div>
          <div className="searchbarExtra">
            {/* <Suspense fallback={<div></div>}>
							<SearchOptions />
						</Suspense> */}
            {!isMobileOnly && (
              <Suspense fallback={<div></div>}>
                <AlternateSearchFilter
                  props={props}
                  noLocation={(e) => searchBtnHandler(e)}
                  loading={loading}
                  hadsearched={hasSearched}
                />
              </Suspense>
            )}
          </div>
        </React.Fragment>
      )}
      {!options && !isMobileOnly && page !== "bp" && (
        <Suspense fallback={<div></div>}>
          <AlternateSearchFilter
            props={props}
            noLocation={(e) => searchBtnHandler(e)}
            page={page}
            loading={loading}
            hadsearched={hasSearched}
          />
        </Suspense>
      )}
    </div>
  );
};

export default AlternateSearchBar;
