const AutoSuggestionLocationModel = (json, lang, translate, value) => {
  let data = [];
  if (json) {
    // first let the municipalities load in together with their submunicipalities
    for (let i = 0; i < json.length; i++) {
      let child = json[i];
      let name =
        child._source[`name_${lang}`] ||
        child._source.name_nl ||
        child._source.name_fr ||
        child._source.name_de ||
        child._source.name_en;
      
      let sub_name =
        child._source[`name_${lang}`] ||
        child._source.name_nl ||
        child._source.name_fr ||
        child._source.name_de ||
        child._source.name_en;
      let slug = child._source.slug;
      let nameNL = child._source.name_nl ? child._source.name_nl : "";
      let nameFR = child._source.name_fr ? child._source.name_fr : "";
      let alt_nameNL = child._source.alt_name_nl ? child._source.alt_name_nl : "";
      let alt_nameFR = child._source.alt_name_fr ? child._source.alt_name_fr : "";
      let nameDE = child._source.name_de ? child._source.name_de : "";
      let nameEN = child._source.name_en ? child._source.name_en : "";
      let postal_code = child._source.pkancode;
      let nis = child._source.nis_code_1977;


      if (
        +postal_code === +value ||
        name.toLowerCase().includes(value.toLowerCase()) ||
        nameNL.toLowerCase().includes(value.toLowerCase()) ||
        alt_nameNL.toLowerCase().includes(value.toLowerCase()) ||
        nameFR.toLowerCase().includes(value.toLowerCase()) ||
        alt_nameFR.toLowerCase().includes(value.toLowerCase()) ||
        nameDE.toLowerCase().includes(value.toLowerCase()) ||
        nameEN.toLowerCase().includes(value.toLowerCase()) ||
        nis.toString().toLowerCase().includes(value.toLowerCase())
        ) {
        data.push({
          name: name,
          slug: slug,
          id: nis,
          postal_code: postal_code,
          text: `${postal_code} | ${sub_name}`,
          type: "municipality",
          sub_municipality_name: `${sub_name}`,
          nameNL: nameNL,
          nameFR: nameFR,
          nameDE: nameDE,
          nameEN: nameEN,
        });
      }
      if (child._source.submunicipality_set.length > 0) {
        for (let j = 0; j < child._source.submunicipality_set.length; j++) {
          let item = child._source.submunicipality_set[j];
          let same_as_parent = false;

          let item_postal_code = item.pkancode;
          let item_nis = item.nis_code_1977;

          let item_name =
            item[`name_${lang}`] ||
            item.name_nl ||
            item.name_fr ||
            item.name_de ||
            item.name_en;
          let item_slug = item.slug;

          if (item_name === name) {
            same_as_parent = true;
          }
          if (
            (item_postal_code !== null && +item_postal_code === +value) ||
            (postal_code !== null && +postal_code === +value) ||
            name.toLowerCase().includes(value.toLowerCase()) ||
            nameNL.toLowerCase().includes(value.toLowerCase()) ||
            nameFR.toLowerCase().includes(value.toLowerCase()) ||
            nameDE.toLowerCase().includes(value.toLowerCase()) ||
            nameEN.toLowerCase().includes(value.toLowerCase())  ||
            item_name.toLowerCase().includes(value.toLowerCase()) ||
            (item.name_nl && item.name_nl.toLowerCase().includes(value.toLowerCase())) ||
            (item.name_fr && item.name_fr.toLowerCase().includes(value.toLowerCase())) ||
            (item.name_de && item.name_de.toLowerCase().includes(value.toLowerCase())) ||
            (item.name_en && item.name_en.toLowerCase().includes(value.toLowerCase())) ||
            item_nis.toLowerCase().includes(value.toLowerCase())
          ) {

            data.push({
              name: item_name,
              slug: item_slug,
              postal_code: item_postal_code,
              id: item_nis,
              text: `${item_postal_code} | ${item_name}${same_as_parent ? ` (${translate("sub_municipality").toLowerCase()})` : ""}`,
              type: `submunicipality`,
              sub_municipality_name: `${item_name}${same_as_parent ? ` (${translate("sub_municipality").toLowerCase()})`: ""}`,
            });
          }
        }
      }
    }
  }
  for (let i = 0; i < json.length; i++) {
    if (
      json[i]._source.submunicipality_set &&
      json[i]._source.submunicipality_set.length > 0
    )
      for (let j = 0; j < json[i]._source.submunicipality_set.length; j++) {
        let child = json[i]._source.submunicipality_set[j];
        let object;

        let name =
          child[`name_${lang}`] ||
          child.name_nl ||
          child.name_fr ||
          child.name_de ||
          child.name_en;
        let nameNL = child.name_nl ? child.name_nl : "" ;
        let nameFR = child.name_fr ? child.name_fr : "" ;
        let alt_nameNL = child.alt_name_nl ? child.alt_name_nl : "" ;
        let alt_nameFR = child.alt_name_fr ? child.alt_name_fr : "" ;

        let sub_name =
          child[`name_${lang}`] ||
          child.name_nl ||
          child.name_fr ||
          child.name_de ||
          child.name_en;
        let slug = child.slug;
        let postal_code = child.pkancode;
        let nis = child.nis_code_1977;

        object = {
          name: name,
          slug: slug,
          id: nis,
          postal_code: postal_code,
          text: `${postal_code} | ${sub_name}`,
          type: "municipality",
          sub_municipality_name: `${sub_name}`,
        };

        const found = data.some((el) => el.id === nis);
        let checkup =
          name.toLowerCase().includes(value.toLowerCase()) ||
          nameNL.toLowerCase().includes(value.toLowerCase()) ||
          alt_nameNL.toLowerCase().includes(value.toLowerCase()) ||
          nameFR.toLowerCase().includes(value.toLowerCase()) ||
          alt_nameFR.toLowerCase().includes(value.toLowerCase()) ||
          postal_code.toString().toLowerCase().includes(value.toLowerCase()) ||
          nis.toString().toLowerCase().includes(value.toLowerCase());


        if (child.pkancode !== null && !found && checkup) {
          data.push({
            name: name,
            slug: slug,
            id: nis,
            text: `${postal_code} | ${sub_name}`,
            type: "submunicipality",
            postal_code: postal_code,
            sub_municipality_name: `${sub_name}`,
            nameNL: nameNL,
            nameFR: nameFR,
          });
        }
      }
  }
  
  return data;
};

export default AutoSuggestionLocationModel;
