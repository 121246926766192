const LocationModal = (translate, json, lang, value_1) => {
  // Parse multiple municipalities
  let municipalities = value_1.split(",").map((m) => m.trim());
  let dataList = [];
  for (const municipalitySlug of municipalities) {
    let data = {};
    let municipality = json;
    let found = false;

    // Check submunicipalities
    for (let i = 0; i < municipality.submunicipality_set.length; i++) {
      const submunicipality = municipality.submunicipality_set[i];
      if (municipalitySlug === submunicipality.slug) {
        
        data.name = submunicipality.name_nl || submunicipality.name_fr || submunicipality.name_de || submunicipality.name_en;
        data.nameNL = submunicipality.name_nl;
        data.nameFR = submunicipality.name_fr;
        data.nameEN = submunicipality.name_en;
        data.nameDE = submunicipality.name_de;
        data.slug = submunicipality.slug;
        data.logo = municipality.logo ? municipality.logo : "";
        data.local_logo = municipality.project_logo ? municipality.project_logo : "";
        data.local_logo.png = "";
        data.active_logo = municipality.has_collaboration;
        data.type = "submunicipality";
        data.postal_code = submunicipality.pkancode;
        data.nis = municipality.nis_code;
        data.url = municipality.url ? municipality.url : "";
        found = true;
        break;
      }
    }

    // If not found in submunicipalities, use municipality data
    if (!found) {
      data.name = municipality.name_nl || municipality.name_fr || municipality.name_de || municipality.name_en;
      data.nameNL = municipality.name_nl;
      data.nameFR = municipality.name_fr;
      data.nameEN = municipality.name_en;
      data.nameDE = municipality.name_de;
      data.slug = municipality.slug;
      data.logo = municipality.logo ? municipality.logo : "";
      data.local_logo = municipality.project_logo ? municipality.project_logo : "";
      data.local_logo.png = "";
      data.active_logo = municipality.has_collaboration;
      data.postal_code = municipality.pkancode;
      data.nis = municipality.nis_code;
      data.geo_type = municipality.is_city === true ? "city" : "municipality";
      data.type = "municipality";
      data.children = [];
      data.url = municipality.url ? municipality.url : "";
    }

    // Add children
    if (municipality && municipality.submunicipality_set && municipality.submunicipality_set.length > 0) {
      for (let i = 0; i < municipality.submunicipality_set.length; i++) {
        const child = municipality.submunicipality_set[i];
        const name = child.name_nl;
        const slug = child.slug;
        const postal_code = child.pkancode;
        const nis = child.nis_code_1977;
        const same_as_parent = name === data.nameNL;

        if (child.postal_code !== null && !found) {
          data.children.push({
            name: `${name} ${
              same_as_parent ? `(${translate("sub_municipality")})` : ""
            }`,
            slug: slug,
            postal_code: postal_code,
            type: "submunicipality",
            nis: nis,
          });
        }
      }
    }

    dataList.push(data);
  }

  return dataList;
};

export default LocationModal;
