const LocationByName = (translate, json, lang, type, value_1) => {
  let data = {};
  let municipality = json;
  data.name =
    municipality[`name_${lang}`] ||
    municipality.name_nl ||
    municipality.name_fr ||
    municipality.name_de ||
    municipality.name_en;
  data.nameNL = municipality.name_nl;
  data.nameFR = municipality.name_fr;
  data.nameEN = municipality.name_en;
  data.nameDE = municipality.name_de;
  data.slug = municipality.slug;
  data.logo = municipality.logo ? municipality.logo : "";
  data.local_logo = municipality.project_logo ? municipality.project_logo : "";
  data.local_logo.png = "";
  data.active_logo = municipality.has_collaboration;

  data.postal_code = municipality.pkancode;
  data.nis = municipality.nis_code;
  data.type = municipality.is_city === true ? "city" : "municipality";
  data.children = [];
  data.url = municipality.url ? municipality.url : "";

  if (
    municipality &&
    municipality.submunicipality_set &&
    municipality.submunicipality_set.length > 0
  ) {
    for (let i = 0; i < municipality.submunicipality_set.length; i++) {
      let child = municipality.submunicipality_set[i];
      let name =
        child[`name_${lang}`] ||
        child.name_nl ||
        child.name_fr ||
        child.name_de ||
        child.name_en;
      let slug = child.slug;
      let postal_code = child.pkancode;
      let nis = child.nis_code_1977;
      let same_as_parent =
        name === data[`name${lang.toUpperCase()}`] ||
        name === data.nameNL ||
        name === data.nameFR ||
        name === data.nameDE ||
        name === data.nameEN
          ? true
          : "";

      if (child.postal_code !== null) {
        data.children.push({
          name: `${name} ${
            same_as_parent ? `(${translate("sub_municipality")})` : ""
          }`,
          slug: slug,
          postal_code: postal_code,
          type: "submunicipality",
          nis: nis,
        });
      }
    }
  }
  return data;
};

export default LocationByName;
