import "./library/styles/App.css";
import "./library/styles/BusinessPage.css";
import "./library/styles/Cookie.css";
import "./library/styles/Footer.css";
import "./library/styles/Infobar.css";
import "./library/styles/Navigation.css";
import "./library/styles/Pagination.css";
import "./library/styles/Privacy.css";
import "./library/styles/Results.css";
import "./library/styles/Search.css";
import "./library/styles/Temporary.css";
import "./library/styles/VKeyboard.css";
import "@identitybuilding/idb-react-ui-elements/dist/styles/Animations.css";
import "@identitybuilding/idb-react-ui-elements/dist/styles/Colors.css";
import "react-simple-keyboard/build/css/index.css";
import "lazysizes";
import "lazysizes/plugins/parent-fit/ls.parent-fit";

import { BrowserRouter, Route, Switch } from "react-router-dom";
import { useSelector } from "react-redux";
import React, { lazy, Suspense } from "react";
// import BusinessPage from "./components/BusinessPage";
import CookieMessage from "./components/privacy/CookieMessage";
import Meta from "./components/_general/Meta";
import axios from "axios";
import AlternateResultsPage from "./components/results/AlternateResultsPage";
// import MapSelection from "./components/Map";
// import NotFound from "./components/error/404";
// import Policy from "./components/privacy/Policy";
// import ResultsPage from "./components/results/Results";
// import SearchEngine from "./components/search/SearchEngine";

const SearchEngine = lazy(() => import("./components/search/SearchEngine"));
const BusinessPage = lazy(() => import("./components/BusinessPage"));
const AlternateBusinessPage = lazy(() => import("./components/AlternateBusinessPage"));
const Policy = lazy(() => import("./components/privacy/Policy"));
const ResultsPage = lazy(() => import("./components/results/Results"));
const NotFound = lazy(() => import("./components/error/404"));
const MapSelection = lazy(() => import("./components/Map"));
const SingleComponent = lazy(() =>
  import("./components/_general/SingleComponent")
);

const is100ProcentLokaal = window.location.href.includes('100procentlokaal') || window.location.href.includes('100pourcentlocale');

const testApi = async () => {
  // axios.get("https://elasticsearch.100procentlokaal.be/");
  const response = await axios.get("https://elasticsearch.100procentlokaal.be/_cat/indices?v", {
    auth: {
      username: "ashish",
      password: "QWi7RhJmByGuVmmnZng4",
    },
  });
  // console.log(response);
  return response;
}
const App = () => {
  const map = useSelector((state) => state.map);
  const { lang, nis, translate, dev } = useSelector((state) => state.general);
  testApi();
  const search_slug = translate("search_slug", lang); 
  const page_slug = translate("page", lang).toLowerCase();
  const search_slug_options = [
    `/${search_slug}/${page_slug}=:page?/:id?`,
    `/${search_slug}/:value_1?/:value_2?/${page_slug}=:page?/:id?`,
    `/${search_slug}/:value_1?/${page_slug}=:page?/:id?`,
    `/${search_slug}/:value_2?/${page_slug}=:page?/:id?`,

    `/${search_slug}/:value_1?/:value_2?/:id?`,
    `/${search_slug}/:value_1?/:id?`,
    `/${search_slug}/:value_2?/:id?`,
  ];

  return (
    <BrowserRouter>
      <script>
        {/* <link href="https://cdn.ondernemersnetwerk.be/font/Fonts.css" rel="preload" /> */}
        <link
          href="https://fonts.cdnfonts.com/css/heebo?styles=20333,20334,20331,20332,20329,20330,20328"
          rel="preload"
        />
        <link
          href="https://fonts.googleapis.com/icon?family=Material+Icons"
          rel="stylesheet preload"
        />
      </script>

      <div
        className={`App ${nis ? "nis" : ""}`}
        style={{ overflow: map.show ? "hidden" : null }}
      >
        <Meta />
        {map.show && (
          <Suspense fallback={<div></div>}>
            <MapSelection />
          </Suspense>
        )}

        {dev && (
          <div class="ribbon-wrapper">
            {" "}
            <div class="ribbon">DEV-MODE</div>{" "}
          </div>
        )}
        <CookieMessage />
        <Suspense fallback={<div></div>}>
          <Switch>
            <Route
              path="/components/:businessid/:mod"
              render={(props) => <SingleComponent {...props} />}
            />
            <Route
              exact
              path="/:keyboard?"
              render={(props) => <SearchEngine {...props} />}
            />
            {search_slug_options.map((path, index) => (
              <Route
                key={index}
                path={path}
                render={(props) =>
                  is100ProcentLokaal ? (
                    <AlternateResultsPage {...props} />
                  ) : (
                    <ResultsPage {...props} />
                  )
                }
              />
            ))}
            <Route
              path="/business/:id/:name?/:mod?/:live?"
              // render={(props) => <BusinessPage {...props} />}
              render={(props) =>
                is100ProcentLokaal ? (
                  <AlternateBusinessPage {...props} />
                ) : (
                  <BusinessPage {...props} />
                )
              }
            />
            <Route
              path="/business/:id/:name?"
              // render={(props) => <BusinessPage {...props} />}
              render={(props) =>
                is100ProcentLokaal ? (
                  <AlternateBusinessPage {...props} />
                ) : (
                  <BusinessPage {...props} />
                )
              }
            />
            <Route
              path="/privacy/policy"
              render={(props) => <Policy {...props} />}
            />
            <Route
              path="/:id?/:value?"
              render={(props) => <SearchEngine {...props} />}
            />
            <Route exact path="*" render={(props) => <NotFound {...props} />} />
          </Switch>
        </Suspense>
      </div>
    </BrowserRouter>
  );
};

export default App;
