import Axios from "axios";
import { instanceOf } from "prop-types";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

var engine = require("store/src/store-engine");
var storages = [require("store/storages/cookieStorage")];
var plugins = [
  require("store/plugins/defaults"),
  require("store/plugins/expire"),
];
var store = engine.createStore(storages, plugins);

let host = window.location.hostname.replace(/^[^.]*\.(?=\w+\.\w+$)/, "");

let livePreview = store.get("livePreview");

const DetailEntity = async ({ lang, id, live }) => {
  let entity_model = require(`../models/DetailsModel`).default;

  // let style_model = require(`../models/StyleModel`).default;
  let error = false;
  let entity = "";
  // let style = {}

  // Example: https://api.ondernemersnetwerk.be/public/results/business?detail-id=FrontBusiness-BE0444672645
  await Axios.get(
    `${process.env.REACT_APP_BASE_MEDIA_URL}/core/api/business/${id}/id/?lang=${lang}`
  )
    .then((resp) => {
      entity = entity_model(
        resp.data,
        process.env.REACT_APP_BASE_MEDIA_URL,
        lang
      );
    })
    .catch((err) => (error = true));

  // also get the stylesheet
  // if (entity.css_path) {
  //     await Axios.get(`${process.env.REACT_APP_BASE_MEDIA_URL}${entity.css_path}`)
  //         .then(resp => {
  //             style = style_model(resp.data)
  //             entity['style'] = style
  //         })
  // }

  return { entity: entity, error: error };
};

const ReadEntity = async ({ id, lang, live }) => {
  let entity_model = require(`../models/ResultsModel`).default;
  // let style_model = require(`../models/StyleModel`).default;
  let error = false;
  let entity = "";
  // let style = {}

  // Example: https://api.ondernemersnetwerk.be/public/results/business?id=FrontBusiness-BE0428101
  await Axios.get(
    `${process.env.REACT_APP_BASE_MEDIA_URL}/public/results/business?id=${id}${livePreview || live ? `&time=${Date.now().toString()}` : ""
    }&lang=${lang}&domain=${host}`
  )
    // await Axios.get(`${process.env.REACT_APP_BASE_MEDIA_URL}/public/results/business?id=FrontBusiness-BE0476887632-BE2101644560`)
    .then((resp) => {
      entity = entity_model(
        resp.data,
        process.env.REACT_APP_BASE_MEDIA_URL,
        lang
      );
    })
    .catch((err) => (error = true));

  // also get the stylesheet
  // if (entity.css_path) {
  //     await Axios.get(`${process.env.REACT_APP_BASE_MEDIA_URL}${entity.css_path}`)
  //         .then(resp => {
  //             style = style_aumodel(resp.data)
  //             entity['style'] = style
  //         })
  // }

  return { entity: entity, error: error };
};

const AutoSuggestionKeyword = async ({ value, lang }) => {
  let as_model = require(`../models/AutoSuggestionModel`).default;

  // let searchVal = [`${value}`, `${value}*`, `*${value}*`, `${value}~`];

  let auto_suggestion = [];
  // for (let i = 0; i < searchVal.length; i++) {
  // let option = {
  //   size: 581,
  //   query: {
  //     bool: {
  //       must: [
  //         {
  //           query_string: {
  //             query: searchVal[i],
  //             fields: [`name_${lang}`, "alternative_names.name"],
  //             fuzziness: 1,
  //           },
  //         },
  //       ],
  //     },
  //   },
  // };
  if (value.includes('(')) {
    value.replace('(', '\\(')
  }
  if (value.includes(')')) {
    value.replace(')', '\\)')
  }

  let option = {
    "from": 0,
    "size": 30,
    "query": {
      "bool": {
        "minimum_should_match": 1,
        "should": [
          {
            "simple_query_string": {
              "query": value,
              "fields": [
                "name_nl",
                "alternative_names.name"
              ],
              "boost": 1
            }
          },
          {
            "match_phrase_prefix": {
              "name_nl": {
                "query": `${value}~`,
                "boost": 2
              }
            }
          },
          {
            "match_phrase_prefix": {
              "alternative_names.name": {
                "query": `${value}~`,
                "boost": 2
              }
            }
          },
          {
            "fuzzy": {
              "name_nl": {
                "value": `*${value}*`,
                "boost": 10,
                "fuzziness": 0
              }
            }
          },
          {
            "fuzzy": {
              "alternative_names.name": {
                "value": `*${value}*`,
                "boost": 10,
                "fuzziness": 0
              }
            }
          }
        ]
      }
    }
  }
  if (lang === "fr") {
    option = {
      "from": 0,
      "size": 30,
      "query": {
        "bool": {
          "minimum_should_match": 1,
          "should": [
            {
              "simple_query_string": {
                "query": value,
                "fields": [
                  "name_fr",
                  "alternative_names.name"
                ],
                "boost": 1
              }
            },
            {
              "match_phrase_prefix": {
                "name_fr": {
                  "query": `${value}~`,
                  "boost": 2
                }
              }
            },
            {
              "match_phrase_prefix": {
                "alternative_names.name": {
                  "query": `${value}~`,
                  "boost": 2
                }
              }
            },
            {
              "fuzzy": {
                "name_fr": {
                  "value": `*${value}*`,
                  "boost": 10,
                  "fuzziness": 0
                }
              }
            },
            {
              "fuzzy": {
                "alternative_names.name": {
                  "value": `*${value}*`,
                  "boost": 10,
                  "fuzziness": 0
                }
              }
            }
          ]
        }
      }
    }
  }

  await Axios.get(
    `${process.env.REACT_APP_BASE_MEDIA_URL2}categories/_search`,
    {
      auth: {
        username: `${process.env.REACT_APP_ESUSERNAME}`,
        password: `${process.env.REACT_APP_ESPSW}`,
      },
      params: {
        source_content_type: "application/json",
        source: JSON.stringify(option),
      },
    }
  )
    .then((resp) => {
      let pushCount = 0;
      if (auto_suggestion.length === 0 && resp.data.hits.hits.length !== 0) {
        resp.data.hits.hits.forEach((value, index) => {
          auto_suggestion.push(as_model(value._source, "category", lang));
        });
      } else {
        for (let j = 0; j < resp.data.hits.hits.length; j++) {
          let inArray = auto_suggestion.some(
            (e) => e.id === resp.data.hits.hits[j]._source.code
          );
          if (!inArray) {
            auto_suggestion.push(
              as_model(resp.data.hits.hits[j]._source, "category", lang)
            );
          }
        }
        var sortedData = auto_suggestion.filter(function (el) {
          return auto_suggestion.indexOf(el.name) >= 0;
        });
      }
    })
    .catch((err) => (err ? err : ""));
  // }

  return auto_suggestion;
};

const searchLocation = async ({ translate, lang, name, nis, excact }) => {
  let filteredName = name
    .replace(/è/g, "e")
    .replace(/é/g, "e")
    .replace(/ê/g, "e")
    .replace(/ë/g, "e")
    .replace(/á/g, "a")
    .replace(/à/g, "a")
    .replace(/â/g, "a")
    .replace(/ä/g, "a")
    .replace(/ü/g, "u")
    .replace(/û/g, "u")
    .replace("(stad)", "")
    .replace("(ville)", "")
    .replace("(stadt)", "")
    .replace("city", "")
    .replace(" ", "")
    .replace("œ", "oe")


  const location_by_name_model = require("../models/LocationByName").default;
  let location = "";
  // await Axios.get(`${process.env.REACT_APP_BASE_MEDIA_URL}/location/nis/by-name/${filteredName}?lang=${lang}${nis || excact ? '&exact=true&time=1' : ''}&domain=${host}`)
  // await Axios.get(`${process.env.REACT_APP_BASE_MEDIA_URL}/geo/api/municipality/${filteredName}/?lang=${lang}`)
  //     .then(resp => {
  //         let data = ''
  //         data = resp.data;
  //         location = location_by_name_model(translate, data, lang, 'municipality');

  //         // determine if submunicipality has been picked / is in the url
  //         for(let i = 0; i < location.children.length; i++){
  //             if (location.children[i].slug === filteredName) {
  //                 location = location.children[i]
  //             }
  //         }
  //     })
  //     .catch(err => err ? err : "");

  let option = {
    from: 0,
    size: 30,
    query: {
      simple_query_string: {
        query: `${filteredName}`,
        fields: [
          `submunicipality_set.name_nl`,
          `submunicipality_set.name_fr`,
          `submunicipality_set.name_de`,
          `submunicipality_set.name_en`,
        ],
        // tie_breaker: 0.3,
      },
    },
  };
  Axios.get(
    `${process.env.REACT_APP_BASE_MEDIA_URL2}municipalities/_search`,
    {
      auth: {
        username: `${process.env.REACT_APP_ESUSERNAME}`,
        password: `${process.env.REACT_APP_ESPSW}`,
      },
      params: {
        source_content_type: "application/json",
        source: JSON.stringify(option),
      },
    }
  )
    .then((res) => {
      let data = res.data.hits.hits[0]["_source"];
      location = location_by_name_model(translate, data, lang, "municipality");
    })
    .catch((err) => (err ? err : ""));

  return location;
};

// Api call to get results on the resultspage
const SearchEntity = async ({
  keyword,
  lang,
  data,
  location,
  pagination,
  translate,
  firstId,
  live,
  as_category,
  categoryID,
  serviceFilter,
  source,
}) => {
  let loc = location
    .replace(/è/g, "e")
    .replace(/é/g, "e")
    .replace(/ê/g, "e")
    .replace(/ë/g, "e")
    .replace(/á/g, "a")
    .replace(/à/g, "a")
    .replace(/â/g, "a")
    .replace(/ä/g, "a")
    .replace(/ü/g, "u")
    .replace(/û/g, "u")
    .replace("(stad)", "")
    .replace("(ville)", "")
    .replace("(stadt)", "")
    .replace("city", "")
    .replace(" ", "")
    .replace("œ", "oe");

  // console.log("Location slug:", loc);

  let facilities = serviceFilter
    .split(",")
    .map(item => item.trim())
    .filter(item => item !== "")
    .map(item => String(item));


  let entities = "";
  let error = false;
  let pages = {};
  let count = 0;
  let ids = [];
  let from = 0;
  let query = {};

  let shouldLength;
  let filter;
  let shouldBody = [];
  let fields = [
    `name_nl^4`,
    `name_fr^4`,
    `name_de^4`,
    `name_en^4`,
    `from_enterprise.name_nl^3`,
    `from_enterprise.name_fr^3`,
    `from_enterprise.name_de^3`,
    `from_enterprise.name_en^3`,
    `from_enterprise.official_name_nl^2`,
    `from_enterprise.official_name_fr^2`,
    `from_enterprise.official_name_de^2`,
    `from_enterprise.official_name_en^2`,
    `from_enterprise.abbreviation_nl^1`,
    `from_enterprise.abbreviation_fr^1`,
    `from_enterprise.abbreviation_de^1`,
    `from_enterprise.abbreviation_en^1`,
    `establishmentactivitylinks.category__name_${lang}^20`,
    `establishmentactivitylinks.category.alternative_category_names.name^20`,
    `from_enterprise.enterpriseactivitylinks.category__name_${lang}^20`,
    `from_enterprise.enterpriseactivitylinks.alternative_names.name^20`,
  ];
  if (categoryID !== "") {
    fields = [
      `establishmentactivitylinks.category__name_${lang}`,
      `from_enterprise.enterpriseactivitylinks.category__name_${lang}`,
    ];
  }

  filter = [
    { term: { active: true } }
  ];


  for (let i = 0; i < facilities.length; i++) {
    shouldBody.push({
      term: {
        "facilities.id": Number(facilities[i]),
      },
    });
    shouldLength = facilities.length;
  }
  // let value = `${keyword}~`
  // if (keyword === "") {
  //   value = `*${keyword}*`
  // }
  // if (categoryID){
  //   value = keyword
  // }
  let value;
  keyword = keyword.replaceAll('%2F', '/')
  if (
    (keyword.length > 1 && !categoryID) ||
    !window.location.href.includes(categoryID)
  ) {
    // if (keyword.toLowerCase().includes(' de ') || keyword.toLowerCase().includes('de ') ){
    value = `\"${keyword}*\"`;
    // }
    // else {
    // }
  } else if (categoryID && typeof categoryID === "number") {
    // value = keyword;
    value = `\"${keyword}\"`;

  } else if (keyword.trim().length === 0) {
    value = `${keyword}*`;
  } else {
    value = `${keyword}~`;
    // if (keyword.includes('-')) {
    //   value = `${keyword}`;
    // }
  }


  // Search Algorithm
  // 1. Full Hit on Activity
  const fullHitOnActivity = (keyword) => {
    return {
      "multi_match": {
        "query": keyword,
        "fields": [
          "establishmentactivitylinks.category__name_nl^120",
          "establishmentactivitylinks.category__name_fr^120",
          "establishmentactivitylinks.category__name_de^120",
          "establishmentactivitylinks.category__name_en^120",
          "establishmentactivitylinks.alternative_names.name^120",
          "from_enterprise.enterpriseactivitylinks.category__name_nl^120",
          "from_enterprise.enterpriseactivitylinks.category__name_fr^120",
          "from_enterprise.enterpriseactivitylinks.category__name_de^120",
          "from_enterprise.enterpriseactivitylinks.category__name_en^120",
          "from_enterprise.enterpriseactivitylinks.alternative_names.name^120",
        ],
        "type": "phrase"
      }
    };
  };

  // 2. Full Hit on Establishment Name
  const fullHitOnEstablishmentName = (keyword) => {
    return {
      "multi_match": {
        "query": keyword,
        "fields": [
          "name_nl^110",
          "name_fr^110",
          "name_de^110",
          "name_en^110"
        ],
        "type": "phrase"
      }
    };
  };

  // 3. Start Hit on Activity
  const startHitOnActivity = (keyword) => {
    return {
      "multi_match": {
        "query": keyword,
        "fields": [
          "establishmentactivitylinks.category__name_nl^90",
          "establishmentactivitylinks.category__name_fr^90",
          "establishmentactivitylinks.category__name_de^90",
          "establishmentactivitylinks.category__name_en^90",
          "from_enterprise.enterpriseactivitylinks.category__name_nl^90",
          "from_enterprise.enterpriseactivitylinks.category__name_fr^90",
          "from_enterprise.enterpriseactivitylinks.category__name_de^90",
          "from_enterprise.enterpriseactivitylinks.category__name_en^90"
        ],
        "type": "phrase_prefix"
      }
    };
  };

  // 4. Start Hit on Establishment Name
  const startHitOnEstablishmentName = (keyword) => {
    return {
      "multi_match": {
        "query": keyword,
        "fields": [
          "name_nl^80",
          "name_fr^80",
          "name_de^80",
          "name_en^80"
        ],
        "type": "phrase_prefix"
      }
    };
  };

  // 5. Sub Hit on Activity Name
  const subHitOnActivityName = (keyword) => {
    return {
      "bool": {
        "should": [
          {
            "wildcard": {
              "establishmentactivitylinks.category__name_nl": {
                "value": `${keyword}*`,
                "boost": 70
              }
            }
          },
          {
            "wildcard": {
              "establishmentactivitylinks.category__name_fr": {
                "value": `${keyword}*`,
                "boost": 70
              }
            }
          },
          {
            "wildcard": {
              "establishmentactivitylinks.category__name_de": {
                "value": `${keyword}*`,
                "boost": 70
              }
            }
          },
          {
            "wildcard": {
              "establishmentactivitylinks.category__name_en": {
                "value": `${keyword}*`,
                "boost": 70
              }
            }
          },
          {
            "wildcard": {
              "from_enterprise.enterpriseactivitylinks.category__name_nl": {
                "value": `${keyword}*`,
                "boost": 70
              }
            }
          },
          {
            "wildcard": {
              "from_enterprise.enterpriseactivitylinks.category__name_fr": {
                "value": `${keyword}*`,
                "boost": 70
              }
            }
          },
          {
            "wildcard": {
              "from_enterprise.enterpriseactivitylinks.category__name_de": {
                "value": `${keyword}*`,
                "boost": 70
              }
            }
          },
          {
            "wildcard": {
              "from_enterprise.enterpriseactivitylinks.category__name_en": {
                "value": `${keyword}*`,
                "boost": 70
              }
            }
          }
        ]
      }
    };
  };



  // 6. Sub Hit on Establishment Name
  const subHitOnEstablishmentName = (keyword) => {
    return {
      "bool": {
        "should": [
          {
            "wildcard": {
              "name_nl": {
                "value": `${keyword}*`,  // Adding wildcard on both sides
                "boost": 60
              }
            }
          },
          {
            "wildcard": {
              "name_fr": {
                "value": `${keyword}*`,
                "boost": 60
              }
            }
          },
          {
            "wildcard": {
              "name_de": {
                "value": `${keyword}*`,
                "boost": 60
              }
            }
          },
          {
            "wildcard": {
              "name_en": {
                "value": `${keyword}*`,
                "boost": 60
              }
            }
          }
        ],
        "boost": 60
      }
    };
  };


  // 7. Full Hit on Enterprise Name
  const fullHitOnEnterpriseName = (keyword) => {
    return {
      "multi_match": {
        "query": keyword,
        "fields": [
          "from_enterprise.official_name_nl^50",
          "from_enterprise.official_name_fr^50",
          "from_enterprise.official_name_de^50",
          "from_enterprise.official_name_en^50"
        ],
        "type": "phrase"
      }
    };
  };

  // 8. Start Hit on Enterprise Name
  const startHitOnEnterpriseName = (keyword) => {
    return {
      "multi_match": {
        "query": keyword,
        "fields": [
          "from_enterprise.official_name_nl^45",
          "from_enterprise.official_name_fr^45",
          "from_enterprise.official_name_de^45",
          "from_enterprise.official_name_en^45"
        ],
        "type": "phrase_prefix"
      }
    };
  };

  // 9. Sub Hit on Enterprise Name
  const subHitOnEnterpriseName = (keyword) => {
    return {
      "bool": {
        "should": [
          {
            "wildcard": {
              "from_enterprise.official_name_nl": {
                "value": `*${keyword}*`,  // Wildcard on both sides
                "boost": 40
              }
            }
          },
          {
            "wildcard": {
              "from_enterprise.official_name_fr": {
                "value": `*${keyword}*`,
                "boost": 40
              }
            }
          },
          {
            "wildcard": {
              "from_enterprise.official_name_de": {
                "value": `*${keyword}*`,
                "boost": 40
              }
            }
          },
          {
            "wildcard": {
              "from_enterprise.official_name_en": {
                "value": `*${keyword}*`,
                "boost": 40
              }
            }
          },
          {
            "wildcard": {
              "from_enterprise.official_name_nl": {
                "value": `${keyword}*`,  // Wildcard only on the right side
                "boost": 40
              }
            }
          },
          {
            "wildcard": {
              "from_enterprise.official_name_fr": {
                "value": `${keyword}*`,
                "boost": 40
              }
            }
          },
          {
            "wildcard": {
              "from_enterprise.official_name_de": {
                "value": `${keyword}*`,
                "boost": 40
              }
            }
          },
          {
            "wildcard": {
              "from_enterprise.official_name_en": {
                "value": `${keyword}*`,
                "boost": 40
              }
            }
          }
        ],
        "boost": 40 // Top-level boost for the entire query
      }
    };
  };

  // 10. Fuzzy Hit on Activity Name
  const fuzzyHitOnActivityName = (keyword) => {
    return {
      "bool": {
        "should": [
          {
            "fuzzy": {
              "establishmentactivitylinks.category__name_nl": {
                "value": keyword,
                "fuzziness": "1",
                "boost": 25
              }
            }
          },
          {
            "fuzzy": {
              "establishmentactivitylinks.category__name_fr": {
                "value": keyword,
                "fuzziness": "1",
                "boost": 25
              }
            }
          },
          {
            "fuzzy": {
              "establishmentactivitylinks.category__name_de": {
                "value": keyword,
                "fuzziness": "1",
                "boost": 25
              }
            }
          },
          {
            "fuzzy": {
              "establishmentactivitylinks.category__name_en": {
                "value": keyword,
                "fuzziness": "1",
                "boost": 25
              }
            }
          },
          {
            "fuzzy": {
              "from_enterprise.enterpriseactivitylinks.category__name_nl": {
                "value": keyword,
                "fuzziness": "1",
                "boost": 25
              }
            }
          },
          {
            "fuzzy": {
              "from_enterprise.enterpriseactivitylinks.category__name_fr": {
                "value": keyword,
                "fuzziness": "1",
                "boost": 25
              }
            }
          },
          {
            "fuzzy": {
              "from_enterprise.enterpriseactivitylinks.category__name_de": {
                "value": keyword,
                "fuzziness": "1",
                "boost": 25
              }
            }
          },
          {
            "fuzzy": {
              "from_enterprise.enterpriseactivitylinks.category__name_en": {
                "value": keyword,
                "fuzziness": "1",
                "boost": 25
              }
            }
          }
        ],
        "minimum_should_match": 1 // Ensures at least one should clause matches
      }
    };
  };


  // 11. Fuzzy Hit on Establishment Name
  const fuzzyHitOnEstablishmentName = (keyword) => {
    return {
      "bool": {
        "should": [
          {
            "fuzzy": {
              "name_nl": {
                "value": keyword,
                "fuzziness": "1",
                "boost": 15 // Adjusted boost
              }
            }
          },
          {
            "fuzzy": {
              "name_fr": {
                "value": keyword,
                "fuzziness": "1",
                "boost": 15
              }
            }
          },
          {
            "fuzzy": {
              "name_de": {
                "value": keyword,
                "fuzziness": "1",
                "boost": 15
              }
            }
          },
          {
            "fuzzy": {
              "name_en": {
                "value": keyword,
                "fuzziness": "1",
                "boost": 15
              }
            }
          }
        ],
        "minimum_should_match": 1
      }
    };
  };

  // 12. Fuzzy Hit on Enterprise Name
  const fuzzyHitOnEnterpriseName = (keyword) => {
    return {
      "bool": {
        "should": [
          {
            "fuzzy": {
              "from_enterprise.official_name_nl": {
                "value": keyword,
                "fuzziness": "1",
                "boost": 10 // Adjusted boost
              }
            }
          },
          {
            "fuzzy": {
              "from_enterprise.official_name_fr": {
                "value": keyword,
                "fuzziness": "1",
                "boost": 10
              }
            }
          },
          {
            "fuzzy": {
              "from_enterprise.official_name_de": {
                "value": keyword,
                "fuzziness": "1",
                "boost": 10
              }
            }
          },
          {
            "fuzzy": {
              "from_enterprise.official_name_en": {
                "value": keyword,
                "fuzziness": "1",
                "boost": 10
              }
            }
          }
        ],
        "minimum_should_match": 1,
        "boost": 10

      }
    };
  };

  const getMunicipalitiesFromProvince = async (provinceSlug) => {
    const response = await Axios.get(
      `${process.env.REACT_APP_BASE_MEDIA_URL}/core/new-api/geo/municipality/` +
      `?paginate=false&from_arrondissement__from_province__slug=${provinceSlug}` +
      `&fields=id,name_nl,name_fr,name_de,name_en,pkancode,slug`,
      {
        headers: {
          Authorization: "Token ae0e4f2df7b0d54ce458ca65ca53849e92691e86",
        },
      }
    );
    return response.data; // returns municipality array
  };

  const getMunicipalitiesFromRegion = async (regionSlug) => {
    const response = await Axios.get(
      `${process.env.REACT_APP_BASE_MEDIA_URL}/core/new-api/geo/municipality/` +
      `?paginate=false&from_arrondissement__from_province__from_region__slug=${regionSlug}` +
      `&fields=id,name_nl,name_fr,name_de,name_en,pkancode,slug`,
      {
        headers: {
          Authorization: "Token ae0e4f2df7b0d54ce458ca65ca53849e92691e86",
        },
      }
    );
    return response.data; // returns municipality array
  };

  // console.log("MunicipalityFromProvince:", municipalityFromProvince.data.map(m => m.slug));
  // console.log("MunicipalityFromRegion:", municipalityFromRegion.data.map(m =>m.slug));

  const getAllMunicipalitiesSlugFromRegionOrProvince = async (locArray) => {
    // You might want to pick exactly one slug that indicates region or province:
    const provinceSlug = locArray.find((loc) => loc.includes('province'));
    const regionSlug = locArray.find((loc) => loc.includes('region'));

    // We'll store the final slugs in this array:
    let allMunicipalitiesSlugs = [];

    if (provinceSlug) {
      // If you have a province slug, fetch municipalities from the province endpoint:
      const provinceMunicipalities = await getMunicipalitiesFromProvince(provinceSlug);
      allMunicipalitiesSlugs = provinceMunicipalities.map(m => m.slug);
    }

    if (regionSlug) {
      // If you have a region slug, fetch municipalities from the region endpoint:
      const regionMunicipalities = await getMunicipalitiesFromRegion(regionSlug);
      allMunicipalitiesSlugs = regionMunicipalities.map(m => m.slug);
    }

    return allMunicipalitiesSlugs;
  };


  // const locationFilter = async (locArray) => {
  //   debugger
  //   if (typeof locArray === "string") {
  //     locArray = locArray.split(",").map((loc) => loc.trim()); // Convert to array
  //   }
  //   if (!Array.isArray(locArray)) {
  //     console.error("locationFilter expects an array, received:", locArray);
  //     return {
  //       bool: {
  //         should: [],
  //       },
  //     };
  //   }

  //   const isSpecialHost =
  //     window.location.host.includes('ondernemersnetwerk.be') ||
  //     window.location.host.includes('reseauentrepreneurs.be') ||
  //     window.location.host.includes('localhost');

  //   const isProvince = locArray.some(loc => loc.includes('province'));
  //   const isRegion = locArray.some(loc => loc.includes('region'));
  //   if (isProvince || isRegion) {
  //     try {
  //       locArray = await getAllMunicipalitiesSlugFromRegionOrProvince(locArray);
  //     } catch (error) {
  //       console.error("Error fetching municipality slugs:", error);
  //       locArray = []; // Fallback to empty array if there's an error
  //     }
  //   }


  //   if (isSpecialHost) {
  //     return {
  //       bool: {
  //         should: locArray.map((loc) => ({
  //           bool: {
  //             should: [
  //               {
  //                 match_phrase: {
  //                   "address.slug.keyword": loc,
  //                 },
  //               },
  //               {
  //                 nested: {
  //                   path: "address.findable_in",
  //                   query: {
  //                     match_phrase: {
  //                       "address.findable_in.slug": loc,
  //                     },
  //                   },
  //                 },
  //               },
  //             ],
  //           },
  //         })),
  //       },
  //     };
  //   }
  //   return {
  //     bool: {
  //       should: locArray
  //         .map((loc) => [
  //           {
  //             match_phrase: {
  //               "address.slug.keyword": loc,
  //             },
  //           },
  //           {
  //             match_phrase: {
  //               "address.sub_slug.keyword": loc,
  //             },
  //           },
  //         ])
  //         .flat(),
  //     },
  //   };
  // };

  const locationFilter = (loc) => {
    if (typeof loc === "string") {
      loc = loc.split(",").map((loc) => loc.trim());
    }

    const isProvince = loc.some(loc => loc.includes('province'));
    const isRegion = loc.some(loc => loc.includes('region'));
    if (isProvince || isRegion) {
      try {
        loc = getAllMunicipalitiesSlugFromRegionOrProvince(loc);
      } catch (error) {
        console.error("Error fetching municipality slugs:", error);
        loc = []; // Fallback to empty array if there's an error
      }
    }
    const formattedLoc = Array.isArray(loc) ? (loc.length === 1 ? loc[0] : loc) : loc;



    const query = window.location.host.includes('ondernemersnetwerk.be') ||
      window.location.host.includes('reseauentrepreneurs.be') ||
      window.location.host.includes('localhost') ? {
      "bool": {
        "minimum_should_match": 1,
        "should": [
          (Array.isArray(loc) && loc.length > 1) ?
            { "terms": { "address.slug.keyword": formattedLoc } } :
            {
              "match_phrase": { "address.slug.keyword": formattedLoc }
            },
          {
            "nested": { // Adding support for the nested "findable_in" field
              "path": "address.findable_in",
              "query":
                (Array.isArray(loc) && loc.length > 1) ?
                  { "terms": { "address.findable_in.slug": formattedLoc } } :
                  { "match_phrase": { "address.findable_in.slug": formattedLoc } }
            }
          }
        ]
      }
    } : {
      bool: {
        should: loc
          .map((loc) => [
            {
              match_phrase: {
                "address.slug.keyword": formattedLoc,
              },
            },
            {
              match_phrase: {
                "address.sub_slug.keyword": formattedLoc,
              },
            },
          ])
          .flat(),
      },
    };
    return query;
  };




  const NewElasticSearchQuery = (keyword, locArray, shouldBody, shouldLength) => {
    return {
      "bool": {
        "should": [
          fullHitOnActivity(keyword),
          fullHitOnEstablishmentName(keyword),
          startHitOnActivity(keyword),
          startHitOnEstablishmentName(keyword),
          subHitOnActivityName(keyword),
          subHitOnEstablishmentName(keyword),
          fullHitOnEnterpriseName(keyword),
          startHitOnEnterpriseName(keyword),
          subHitOnEnterpriseName(keyword),
          fuzzyHitOnActivityName(keyword),
          fuzzyHitOnEstablishmentName(keyword),
          fuzzyHitOnEnterpriseName(keyword)
        ],
        "filter": [
          locationFilter(locArray),
          { term: { active: true } },
          ...shouldBody // Add facilities filters here
        ],
        "minimum_should_match": 1
      }
    };
  };



  if (pagination.current_page !== 1) {
    from = (pagination.current_page - 1) * 30;
  }
  let option = {
    from: from,
    size: 30,
    query: NewElasticSearchQuery(keyword, loc, shouldBody, shouldLength),
    sort: [
      {
        has_a_subscription: {
          order: "desc",
        },
        has_logo: {
          order: "desc",
        },
      },
      {
        _script: {
          type: "number",
          order: "desc",
          script: {
            lang: "painless",
            source: `
            int defaultRank = 960;
            if (params._source?.address != null && params._source?.address?.findable_in != null) {
              for (def item : params._source.address.findable_in) {
                if (item.slug == params.loc) {
                  return item.ranking != null ? item.ranking : defaultRank;
                }
              }
            }
            return defaultRank;
          `,
            params: {
              loc: loc // Pass the location dynamically
            }
          }
        }
      },
      { "penalty": { "order": "desc" } },
      { "subscription_value": { "order": "desc" } },
      "_score"
    ],
  };

  // console.log(JSON.stringify(option, null, 2));


  let option2 = {
    query: option.query,
  };

  await Axios.post(
    `${process.env.REACT_APP_BASE_MEDIA_URL2}_search`,
    JSON.stringify(option), // Directly pass the query as the body
    {
      auth: {
        username: process.env.REACT_APP_ESUSERNAME,
        password: process.env.REACT_APP_ESPSW,
      },
      headers: {
        "Content-Type": "application/json", // Set the content type here
      },
      cancelToken: source.token,
    }
  )
    .then((resp) => {
      for (let i = 0; i < resp.data.hits.hits.length; i++) {
        let source = resp.data.hits.hits[i]._source;
        ids.push(source);


      }
    })
    .catch((err) => {
      console.error('Error:', err);
      // Handle the error appropriately
    });



  await Axios.post(
    `${process.env.REACT_APP_BASE_MEDIA_URL2}_count`,
    JSON.stringify(option2), // Send the query as the body of the POST request
    {
      auth: {
        username: process.env.REACT_APP_ESUSERNAME,
        password: process.env.REACT_APP_ESPSW,
      },
      headers: {
        "Content-Type": "application/json", // Ensure the content type is correctly set
      },
      cancelToken: source.token,
    }
  )
    .then((resp) => {
      count = resp.data.count;

      // Handle the response data
      error = resp.data.count === 0 ? translate("no_results") : false;
      let total_pages = Math.ceil(resp.data.count / 30);
      pages = {
        current_page: pagination.current_page,
        next_page: Number(pagination.current_page) + 1,
        prev_page: Number(pagination.current_page) - 1,
        total_pages: total_pages,
      };
    })
    .catch((err) => {
      console.error('Error:', err);
      // Handle the error appropriately
    });


  return {
    entities: entities,
    error: error,
    pagination: pages,
    count: count,
    ids: ids,
  };
};

export {
  AutoSuggestionKeyword,
  DetailEntity,
  SearchEntity,
  searchLocation,
  ReadEntity,
};
