const ResultsModel = (json, api_uri, lang) => {
  let data = {};

  // Enterprise ID, Vat and establishment_number
  data.id = json.id ? json.id : "";
  data.vat_number = json.enterprise ? json.enterprise.dotted_vat_number : "";
  data.establishment_number = json.establishment
    ? json.establishment.dotted_code
    : "";
  data.business_entity_id = json.business_entity_id
    ? json.business_entity_id
    : "";
  data.zoomed = false;

  // Juridical form
  data.juridical_form = "";
  data.hide_abbreviation_in_front_end = "";

  // denominations
  data.enterprise_name = "";
  data.establishment_name = "";
  data.trade_name = "";
  data.name = "";

  // Address information
  data.address = {};

  // Contact information
  data.qr = `${api_uri}/public/business/${data.business_entity_id}/qr.png`;
  data.vcard = `${api_uri}/public/business/${data.id}/vcard`;
  data.telephone = "";
  data.cellphone = "";
  data.website = "";
  data.fax = "";
  data.email = "";

  data.business = { page: true };

  let id = json.id;
  // Use regex to split spaces and "/""
  data.link_to_bp = `${id.replace(/(FrontBusiness-)+/g, "")}/${
    data.establishment_name
      ? data.establishment_name.toLocaleLowerCase().split(/[\s/]/).join("-")
      : data.enterprise_name.toLocaleLowerCase().split(/[\s/]/).join("-")
  }`;

  return data;
};

export default ResultsModel;
