
export const toggleView = id => ({ 
    type: "TOGGLE_VIEW", 
    id 
})

export const toggleB2BC = id => ({ 
    type: "TOGGLE_B2BC", 
    id 
})
